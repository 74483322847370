<template>
    <div class="page">
        <div v-if="user.isOnline">
            <!-- Entete commune mois/ comtpes/ add/delete / solde -->
            <div class="sectionTitle">
                <span v-if="debtUser">{{ debtUser.name }} </span>
                <span v-else>Tous les utilisateurs </span>
            </div>
            <div>
                <div class="accounts" @click="activateDebtsList()">
                    <div class="accountName">
                        <span>Liste des utilisateurs </span>
                        <!-- On ne montre la selection de compte que s'il y a au moins 1 compte a selectionner -->
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-square-fill icon" viewBox="0 0 16 16" v-if="!showUsers">
                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5a.5.5 0 0 1 1 0z"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-square-fill icon" viewBox="0 0 16 16" v-if="showUsers">
                                <path d="M2 16a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2zm6.5-4.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 1 0z"/>
                            </svg>
                        </span>
                    </div>
                    <!-- Si on a un clic sur cette div, on peut la fermer -->
                        <div :class="{
                                    'categories-display': debtsDisplay,
                                    'categories-hide': debtsHide
                                }"
                            v-if="showUsers"
                        >
                        <p @click="resetUser()" class="accountName">Tous les utilisateurs</p>
                        <!-- Lorsqu'on clique sur un compte, l'id est passée à la variable -->
                        <p class="accountName" v-for="debtUser in debtUsers" :key="debtUser.id" @click="selectUser(debtUser.id)">{{ debtUser.name }}</p>
                    </div>
                </div>
            </div>

            <div>
                <center>
                    <!-- Si on a pas d'accout spécifié, on cache le button de delete account -->
                    <div class="semiColons" :style="{'justify-content': debtUser ? null : 'center'}">
                        <button class="button smallButton" @click="activateDebtUserComponent('create')">
                            <span>Ajouter un utilisateur</span>
                        </button>
                        <button 
                            class="button deleteButton smallButton" 
                            v-if="debtUser"
                            @click="activateDebtUserComponent('delete', debtUser.id, debtUser.name)"
                        >
                            <span>Supprimer cet utilisateur</span>
                        </button>
                    </div>
                </center>
            </div>
            
            <div class="field">
                <div class="fieldBody">
                    <div class="balance">
                        <span>Prêt en cours</span>
                        <!-- Ici, on gère la couleur selon l'opérateur du montant -->
                        <span class='positiveAmount'>{{ byAmount }} €</span>
                    </div>
                    <!-- Si c'est le mois courant, on affiche la prévision à la fin du mois -->
                    <div class="balance">
                        <span>Emprunt en cours</span>
                        <!-- Ici, on gère la couleur selon l'opérateur du montant -->
                        <span class='negativeAmount'>{{ toAmount }} €</span>
                    </div>
                </div>
            </div>

            <!-- Détails comptes -->
            <div>
                <div class='field' v-if="filterByDebtUser(unrefundedDebts).length > 0">
                    <div class="fieldTitle">
                        En cours
                    </div>   
                    <div :class="{
                        'fieldBody': true,
                        'operation': true,
                        'groupDate': (index > 0)}"
                        v-for="(debt, index) in filterByDebtUser(unrefundedDebts)" :key="debt.id"
                        @click="activateOperationComponent(debt.operation)"
                    >
                        <div>
                            <span class="nameOperation">{{ debt.operation.name }}</span>
                            <!-- Ici, on gère la couleur selon l'opérateur du montant -->
                            <span :class="{
                                'positiveAmount': debt.type === 'by',
                                'negativeAmount': debt.type === 'to'
                            }">
                                <span>{{ debt.amount }} €</span>
                            </span>
                        </div>
                        <div>
                            <!-- Ici, on gère l'affichage du nom des catégories/moyens de paiement s'il sont définis, sinon "Non défini" -->
                            <span class="typeOperation">{{ typeOperation(debt.type) }}</span>
                            
                            <span class="typeOperation">{{ formatDateOperation(debt.operation.date) }}</span>
                        </div>
                    </div>
                </div>
                <div class='field' v-if="filterByDebtUser(refundedDebts).length > 0">
                    <div class="fieldTitle">
                        Remboursé
                    </div>   
                    <div :class="{
                        'fieldBody': true,
                        'operation': true,
                        'groupDate': (index > 0)}"
                        v-for="(debt, index) in filterByDebtUser(refundedDebts)" :key="debt.id"
                        @click="activateOperationComponent(debt.operation)"
                    >
                        <div>
                            <span class="nameOperation">{{ debt.operation.name }}</span>
                            <!-- Ici, on gère la couleur selon l'opérateur du montant -->
                            <span :class="{
                                'positiveAmount': debt.type === 'by',
                                'negativeAmount': debt.type === 'to'
                            }">
                                <span>{{ debt.amount }} €</span>
                            </span>
                        </div>
                        <div>
                            <!-- Ici, on gère l'affichage du nom des catégories/moyens de paiement s'il sont définis, sinon "Non défini" -->
                            <span class="typeOperation">{{ typeOperation(debt.type) }}</span>
                            
                            <span class="typeOperation">{{ formatDateOperation(debt.operation.date) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <DebtUser
                v-if="showDebtUserModal"
                :modeProp = modeProp
                :debtUserIDProp = debtUserIDProp
                :debtUserNameProp = debtUserNameProp
                @close="showDebtUserModal = false; refreshDebtUsers(); getAllDebts(); debtUser = null"
            />

            <!-- Ici, on part jsute du read pour l'ope -->
            <Operation v-if="showOperationComponent"
                modeProp = 'read'
                :operationProp = operationProp
                :formatDateOperation = formatDateOperation
                :accountsProp = accountsProp
                @close="showOperationComponent = false; refreshDebtUsers(); getAllDebts(); debtUser = null"
            />

        </div>
        <p v-else>Vous devez être connecté pour accéder à cette partie !</p>
    </div>
</template>

<style scoped>
.sectionTitle {
    position: relative;
}

.field {
    height: initial;
    margin-top: 40px;
}

.groupDate {
    margin-top: 5px;
}

.button {
    margin-top: 36px;
    width: 90%;
}

.semiColons {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.semiColons > .button {
  width: 45%;
}

.balance,
.operation,
.operation > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.balance {
    font-size: 18px;
}

.operation {
    padding-top: 5px;
    padding-bottom: 10px;
}

.operation > div {
    width: 100%;
}

.nameOperation {
    color: #696969
}

.typeOperation {
    font-size: 12px
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import DebtUser from '../components/DebtUserComponent.vue'
import Operation from '../components/OperationComponent.vue'

export default {
    data () {
        return {
            debts: null,
            // date est la date qu'on manipule
            date: new Date(),
            byAmount: 0,
            toAmount: 0,
            showUsers: false,
            idUserSelected: null,
            debtUsers: null,
            debtUser: null,
            refundedDebts: [],
            unrefundedDebts: [],
            modeProp: '',
            debtUserIDProp: null,
            debtUserNameProp: null,
            showDebtUserModal: false,
            operationProp: null,
            showOperationComponent: false,
            accountsProp: null,
            debtsDisplay: null,
            debtsHide: null
        }
    },
    computed: {
        ...mapGetters({
            // On importe les données utilisateur
            user: "getUser",
        }),
    },
    components: {
        DebtUser,
        Operation
    },
    methods: {
        ...mapActions({
            // On importe le get des comptes
            getDebts: "debts",
            getDebtUsers: "debtUsers",
            getAccounts: "accounts"
        }),
        triggerDebtsAnimation() {
            if (this.debtsDisplay === null) {
                this.debtsDisplay = true;
                this.debtsHide = false;
            } else {
                this.debtsDisplay = !this.debtsDisplay
                this.debtsHide = !this.debtsHide
            }
        },
        activateDebtsList() {
            // On trigger les animations
            this.triggerDebtsAnimation()
            // Pour que la liste ne soit pas cachée immédiatement a la fermeture de la liste, on met un timeout seulement si on demande la fermeture
            if (this.debtsHide) {
                setTimeout(()=>{
                    this.showUsers = !this.showUsers
                }, 300);
            } else {
                this.showUsers = !this.showUsers
            }
        },
        // Ici, on filtre les méthoides de paiement, pour ne pas afficher la méthode de paiement selectionnée dans la liste de choix
        filterByDebtUser(arrayToFilter) {
            if (this.debtUser) {
                return arrayToFilter.filter(d => d.debt_user_id === this.debtUser.id)
            } else {
                return arrayToFilter
            }
        },
        getAllDebts() {
            // On récupère les comptes liés à l'utilisateur (l'identification de l'utilisateur se fait côté back), avec les opérations du mois selectionné
            this.getDebts()
                .then((response) => {
                    this.debts = response.data
                    // On trie les dettes reçues
                    this.sortDebts()
                }).catch(error => {
                    this.$toast.warning(error)
                })
        },
        selectUser(id) {
            // On récupère le compte selectionné (l'identification de l'utilisateur se fait côté back), avec les opérations du mois selectionné
            for (let debtUser of this.debtUsers) {
                if (id === debtUser.id) {
                    this.debtUser = debtUser
                    // On recalcule les totaux pour ce user
                    this.calculateDebts()
                }
            }
        },
        resetUser() {
            this.debtUser = null
            // On recalcule les totaux
            this.calculateDebts()
        },
        sortDebts() {
            // On reset les tableaux des dettes
            this.refundedDebts = []
            this.unrefundedDebts = []
            // SI on a un compte selectionné
            if (this.debtUser) {
                // On parcours les dettes de l'user en question
                for (let debt of this.debtUser.debt) {
                    if (debt.refunded === 1) {
                        this.refundedDebts.push(debt)
                    } else {
                        this.unrefundedDebts.push(debt)
                    }
                }   
            // Si on a plusieurs comptes           
            } else {
                // On choppe les comptes
                for (let debt of this.debts) {
                    if (debt.refunded === 1) {
                        this.refundedDebts.push(debt)
                    } else {
                        this.unrefundedDebts.push(debt)
                    }
                }
            }
            // On trie les tableaux par date
            this.sortDebtsByDate()
            this.calculateDebts()
        },
        calculateDebts() {
            // On reset les totaux
            this.toAmount = 0
            this.byAmount = 0

            // On filtre les tableaux
            let unrefundedFiltered = this.filterByDebtUser(this.unrefundedDebts)

            for (let debt of unrefundedFiltered) {
                if (debt.type === 'to') {
                    this.toAmount += debt.amount
                } else {
                    this.byAmount += debt.amount
                }
            }
        },
        sortDebtsByDate() {
            // On va sort les deux tableaux
            this.refundedDebts.sort( ( a, b) => {
                // Si la date de l'opé A est supérieur à la date de l'opé B, on la place en premier (on veut un ordre décroissant)
                if (a.created_at > b.created_at) {
                    return -1
                }
                // A l'inverse, Si la date de l'opé A est inférieure à la date de l'opé B, on la place en dernier
                if (a.created_at < b.created_at) {
                    return 1
                }
            });
            this.unrefundedDebts.sort( ( a, b) => {
                // Si la date de l'opé A est supérieur à la date de l'opé B, on la place en premier (on veut un ordre décroissant)
                if (a.created_at > b.created_at) {
                    return -1
                }
                // A l'inverse, Si la date de l'opé A est inférieure à la date de l'opé B, on la place en dernier
                if (a.created_at < b.created_at) {
                    return 1
                }
            });
        },
        formatDateOperation(date) {
            let dateUnformatted = new Date(date)
            return dateUnformatted.toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric'})
        },
        activateDebtUserComponent(mode, id = null, name = null) {
            this.showDebtUserModal = true
            this.modeProp = mode
            this.debtUserIDProp = id
            this.debtUserNameProp = name
        },
        activateOperationComponent(operation) {
            this.showOperationComponent = true
            this.operationProp = operation
        },
        typeOperation(type) {
            // On affiche le type de l'opé en francais. Si c'est by, c'est que le montant est dû par l'utilisateur, sinon c'est dû à l'utilisateur, donc respectivement prêt et emprunt
            return type === 'by' ? 'Prêt' : 'Emprunt'
        },
        refreshDebtUsers() {
            // On récupère les debt users
            this.getDebtUsers()
                .then((response) => {
                    this.debtUsers = response.data
                }).catch(error => {
                    this.$toast.warning(error)
                })
        }
    },
    mounted() {
        //On récupère tous les debt users
        this.refreshDebtUsers()
        // On récupère toutes les dettes
        this.getAllDebts()
        
        // Ici, les accounts on en aura besoin si on veut le détail d'une opé
        // On lance donc la recup des comptes dès le chargement de la page
        this.getAccounts({
            //On est quand même obligés de passer le mois et l'année (c'est une dépendance pour la page accounts, et comme on veut les comptes ici)
            month: this.date.toLocaleString('default', { month: '2-digit'}),
            year: this.date.getFullYear()
        })
            .then((response) => {
                this.accountsProp = response.data
            }).catch(error => {
                this.$toast.warning(error)
            })
    }
};
</script>