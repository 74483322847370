<template>
    <div class="modalContainer">
        <div class="modalContent">
            <div class="modalCloseCross" @click="$emit('close')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="currentColor"
                  class="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
            </div>
            
            <!-- READ -->
            <div class="contentShow" v-if="mode === 'read'">
                <div class="field">
                    <div class="fieldTitle">
                        <span>Catégorie</span>
                    </div>
                    <div class="fieldBody">
                        <span class="read" v-if="mode === 'read'">{{ name }}</span>
                    </div>
                </div>

                <div class="field">
                    <div class="fieldTitle">
                        <span>Couleur</span>
                    </div>
                    <div class="fieldBody fieldBodyCategory">
                        <div :style="{'background-color': color}" v-if="mode === 'read'">{{ color}}</div>
                    </div>
                </div>

                <div class="modalButtons">
                    <button class="modalCancelButton" @click="$emit('close')">Annuler</button>
                    <button @click.prevent="mode = 'edit'">Modifier</button>
                    <button class="deleteButton" @click="mode = 'delete'">Supprimer</button>
                </div>
            </div>

            <!-- EDIT - CREATE -->
            <form @submit.prevent="submitForm" method="post" v-else-if="mode === 'edit' || mode === 'create'">
                <div class="field">
                    <div class="fieldTitle">
                        <span>Catégorie</span>
                    </div>
                    <div class="fieldBody">
                        <input type="text" placeholder="Nom du moyen de paiement" v-model="name"/>
                    </div>
                    <span class="errorMessage" v-if="nameError">Le nom est obligatoire.</span>
                </div>

                <div class="field">
                    <div class="fieldTitle">
                        <span>Couleur</span>
                    </div>
                    <div class="fieldBody fieldBodyCategory">
                        <input type="text" :style="{'background-color': color}" placeholder="Couleur" v-model="color"/>
                    </div>
                    <span class="errorMessage" v-if="colorError">La couleur est obligatoire.</span>
                </div>

                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="defaultCategory">
                    <label class="custom-control-label" for="customSwitch1">Catégorie par défaut</label>
                </div>

                <div class="modalButtons">
                    <button  class="validationButton"> 
                        <input type="submit" value="Valider"/>
                    </button>
                    <button class="modalCancelButton" @click="$emit('close')">Annuler</button>
                </div>
            </form>
            
            <!-- DELETE -->
            <div class="contentShow" v-if="mode === 'delete'">
                <p>Souhaitez-vous vraiment supprimer cette catégorie ?</p>

                <div class="modalButtons">
                    <button class="modalCancelButton" @click="$emit('close')">Annuler</button>
                    <button class="deleteButton" @click="submitForm">Supprimer</button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    props: [
        'modeProp',
        'id',
        'nameProp',
        'colorProp',
        'defaultCategoryProp',
    ],
    data () {
        return {
            // On défini des variables qui prenent la valeur des props, puisqu'elles seront sujettes à modifications
            mode: this.modeProp,
            name: this.nameProp,
            color: this.colorProp,
            defaultCategory: this.defaultCategoryProp,
            colorError: false,
            nameError: false
        }
    },
    computed: {
        ...mapGetters({
            // On importe les données utilisateur
            user: "getUser",
        }),
    },
    methods: {
        ...mapActions({
            updateCategory: "updateCategory",
            storeCategory: "storeCategory",
            deleteCategory: "deleteCategory"
        }),
        submitForm() {
            if(this.mode === 'delete') {
                // On fait appel l'action delete
                this.deleteCategory({
                    id: this.id
                    // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                }).then(() => {
                    let toast = (
                        <div>
                            <span>La catégorie a bien été supprimée.</span>
                        </div>
                    )
                    this.$toast.success(toast)
                    this.$emit('close')
                    // Et ici l'erreur, envoyée par reject
                }).catch(error => {
                    this.$toast.warning(error)
                })
            }
            /*********** VALIDATION DES DONNEES *************/
            this.name === null || this.name === '' ? this.nameError = true : this.nameError = false
            this.color === null || this.color === '' ? this.colorError = true : this.colorError = false

            // On traite les données entrées 
            if (!this.nameError && !this.colorError) {
                if(this.mode === 'edit') {
                    // On fait appel l'action update
                    this.updateCategory({
                        id: this.id,
                        name: this.name,
                        color: this.color,
                        default: this.defaultCategory
                        // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                    }).then(() => {
                        let toast = (
                            <div>
                                <span>La catégorie a bien été mise à jour.</span>
                            </div>
                        )
                        this.$toast.success(toast)
                        this.$emit('close')
                        // Et ici l'erreur, envoyée par reject
                    }).catch(error => {
                        this.$toast.warning(error)
                    })
                } else if (this.mode === 'create') {
                    // On fait appel l'action store
                    this.storeCategory({
                        name: this.name,
                        color: this.color,
                        default: this.defaultCategory
                        // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                    }).then(() => {
                        let toast = (
                            <div>
                                <span>La catégorie a bien été créée.</span>
                            </div>
                        )
                        this.$toast.success(toast)
                        this.$emit('close')
                        // Et ici l'erreur, envoyée par reject
                    }).catch(error => {
                        this.$toast.warning(error)
                    })
                }
            }
        }
    },
}
</script>

<style scoped>
.field {
    margin-top: 0;
}

/* Tous les .field qui sont après des .field */
.field ~ .field {
    margin-top: 45px;
}

.fieldBodyCategory {
    color: white;
}

.fieldBodyCategory > * {
    padding-left: 10px;
}

.modalCloseCross {
    width: 100%
}
</style>
