<template>
    <div class="modalContainer">
        <div class="modalContent">
            <div class="modalCloseCross" @click="$emit('close')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="currentColor"
                  class="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
            </div>
            
            <!-- READ -->
            <div class="contentShow" v-if="mode === 'read'">
                <div class="field">
                    <div class="fieldTitle">
                        <span>Moyen de paiement</span>
                    </div>
                    <div class="fieldBody">
                        <span class="read" v-if="mode === 'read'">{{ name }}</span>
                    </div>
                </div>

                <div class="field">
                    <div class="fieldTitle">
                        <span>Logo</span>
                    </div>
                    <div class="fieldBody">
                        <img :src="logo" alt="logo moyen de paiement" v-if="mode === 'read'"/>
                    </div>
                </div>

                <div class="modalButtons">
                    <button class="modalCancelButton" @click="$emit('close')">Annuler</button>
                    <button @click.prevent="mode = 'edit'">Modifier</button>
                    <button class="deleteButton" @click="mode = 'delete'">Supprimer</button>
                </div>
            </div>

            <!-- EDIT - CREATE -->
            <form @submit.prevent="submitForm" enctype="multipart/form-data" method="post" v-else-if="mode === 'edit' || mode === 'create'">
                <div class="field">
                    <div class="fieldTitle">
                        <span>Moyen de paiement</span>
                    </div>
                    <div class="fieldBody">
                        <input type="text" placeholder="Nom du moyen de paiement" v-model="name"/>
                    </div>
                </div>
                <span class="errorMessage" v-if="nameError">Vous devez définir un nom.</span>

                <div class="field">
                    <div class="fieldTitle">
                        <span>Logo</span>
                    </div>
                    <div class="fieldBody">
                        <!-- On affiche l'image si on edite, sinon on affiche l'input file -->
                        <img :src="logo" alt="logo moyen de paiement" v-if="mode === 'edit'"/>
                        <input class="form-control form-control-sm" type="file" accept="image/svg" @change="uploadImage($event)" v-else-if="mode === 'create'"/>
                    </div>
                </div>
                <input class="form-control form-control-sm" type="file" accept="image/svg" @change="uploadImage($event)" v-if="mode === 'edit'"/>
                <span class="errorMessage" v-if="logoError">Vous devez définir un logo.</span>
            
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="defaultPM">
                    <label class="custom-control-label" for="customSwitch1">Mode de paiement par défaut</label>
                </div>

                <div class="modalButtons">
                    <button  class="validationButton"> 
                        <input type="submit" value="Valider"/>
                    </button>
                    <button class="modalCancelButton" @click="$emit('close')">Annuler</button>
                </div>
            </form>
            
            <!-- DELETE -->
            <div class="contentShow" v-if="mode === 'delete'">
                <p>Souhaitez-vous vraiment supprimer ce mode de paiement ?</p>

                <div class="modalButtons">
                    <button class="modalCancelButton" @click="$emit('close')">Annuler</button>
                    <button class="deleteButton" @click="submitForm">Supprimer</button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    props: [
        'modeProp',
        'id',
        'nameProp',
        'logoProp',
        'defaultPMProp',
    ],
    data () {
        return {
            // On défini des variables qui prenent la valeur des props, puisqu'elles seront sujettes à modifications
            mode: this.modeProp,
            name: this.nameProp,
            logo: this.logoProp,
            uploadedLogo: null,
            defaultPM: this.defaultPMProp,
            nameError: false,
            logoError: false
        }
    },
    computed: {
        ...mapGetters({
            // On importe les données utilisateur
            user: "getUser",
        }),
    },
    methods: {
        ...mapActions({
            updatePaymentMethods: "updatePaymentMethods",
            storePaymentMethods: "storePaymentMethods",
            deletePaymentMethods: "deletePaymentMethods"
        }),
        uploadImage(e) {
            this.uploadedLogo = e.target.files[0]
        },
        submitForm() {
            if(this.mode === 'delete') {
                // On fait appel l'action delete
                this.deletePaymentMethods({
                    id: this.id
                    // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                }).then(() => {
                    let toast = (
                        <div>
                            <span>Le mode de paiement a bien été supprimé.</span>
                        </div>
                    )
                    this.$toast.success(toast)
                    this.$emit('close')
                    // Et ici l'erreur, envoyée par reject
                }).catch(error => {
                    this.$toast.warning(error)
                })
            }

            /************ VALIDATION DES DONNEES **********/
            this.name === null || this.name === '' ? this.nameError = true : this.nameError = false
            this.logo === null && this.uploadedLogo === null ? this.logoError = true : this.logoError = false

            // On traite les données entrées 
            if (!this.nameError && !this.logoError) {
                if(this.mode === 'edit') {
                    // On fait appel l'action update
                    this.updatePaymentMethods({
                        id: this.id,
                        name: this.name,
                        logo: this.uploadedLogo,
                        default: this.defaultPM
                        // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                    }).then(() => {
                        let toast = (
                            <div>
                                <span>Le mode de paiement a bien été mis à jour.</span>
                            </div>
                        )
                        this.$toast.success(toast)
                        this.$emit('close')
                        // Et ici l'erreur, envoyée par reject
                    }).catch(error => {
                        this.$toast.warning(error)
                    })
                } else if (this.mode === 'create') {
                    if (this.uploadedLogo) {
                        // On fait appel l'action store
                        this.storePaymentMethods({
                            name: this.name,
                            logo: this.uploadedLogo,
                            default: this.defaultPM
                            // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                        }).then(() => {
                            let toast = (
                                <div>
                                    <span>Le mode de paiement a bien été créé.</span>
                                </div>
                            )
                            this.$toast.success(toast)
                            this.$emit('close')
                            // Et ici l'erreur, envoyée par reject
                        }).catch(error => {
                            this.$toast.warning(error)
                        })
                    } else {
                        this.logoError = true
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
.field {
    margin-top: 0;
}

/* Tous les .field qui sont après des .field */
.field ~ .field {
    margin-top: 45px;
}

.modalCloseCross {
    width: 100%
}
</style>
