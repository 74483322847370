import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// On défini une instance axios
const api = axios.create({
    // On passe le cookie session a chaque requête
    withCredentials: true,
    headers: {
        // On demande une réponse json
        Accept: 'application/json',
        // On envoie les datas a chaque requête, datas récupérées au login dans le cookie avec laravel_session
        'Access-Control-Allow-Credentials': true,
    },
  })

// Pour l'envoi de formulaire avec fichiers
const apiWithFile = axios.create({
    // On passe le cookie session a chaque requête
    withCredentials: true,
    headers: {
        // On demande une réponse json
        Accept: 'application/json',
        // On envoie les datas a chaque requête, datas récupérées au login dans le cookie avec laravel_session
        'Access-Control-Allow-Credentials': true,
        // Pour l'envoi de formulaire avec fichiers
        'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2)
    },
  })

export default new Vuex.Store({
    // Ceci est la vérité absolue de l'application. Oui, monseigneur...
    state: {
        user: {
            id: 0,
            name: '',
            email: '',
            isAdmin: 0,
            isOnline: false,
            showStats: false
        },
    },
    getters: {
        // On a tout l'objet state a dispo, et donc on retourne ce qu'on veut retourner de cet objet
        getUser: state => {
            return state.user
        }
    },
    mutations: {
        // Obligatoires pour changer un state
        // On peut mettre, en 2e argument, un payload, un objet
        // Toutes les mutations doivent être synchrones
        setUserInfos(state, payload) {
            state.user.id = payload.id,
            state.user.name = payload.name,
            state.user.email = payload.email,
            state.user.isAdmin = payload.is_admin
            state.user.isOnline = true
            state.user.showStats = payload.show_stats
        },
        // On reset les données utilisateur (logout)
        resetUser(state) {
            state.user.id = 0,
            state.user.name = '',
            state.user.email = '',
            state.user.isAdmin = 0
            state.user.isOnline = false
            state.user.showStats = false
        },
    },
    actions: {
        // Les actions lancent des mutations, au lieu de modifier directement le state
        // Les actions peuvent être asynchrones
        // Le context contient tout le store
        // context.commit pour lancer une mutation
        // context.state
        // context.getters
        // context.dispatch pour les actions
        // on peut aussi passer un payload

        /*********************** LOG USER **********************/
        userLog(context, data) {
            // On post sur le chemin de l'api de login, ave les datas en 2e (qu'on a passées à l'appel de la fonction), puis les headers
            return new Promise ((resolve, reject) => {
                api.post(process.env.VUE_APP_API + 'login', data)
                    .then((response) => {
                        // On lance la mutation pour set les informations utilisateur
                        context.commit('setUserInfos', response.data)
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** INSCRIPTION **********************/
        userRegister(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            return new Promise ((resolve, reject) => {
                api.post(process.env.VUE_APP_API + 'register', data)
                    .then((response) => {
                        // On lance la mutation pour set les informations utilisateur, l'api connecte automatiquement l'utilisateur qui vient de s'inscrire
                        context.commit('setUserInfos', response.data)
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },

        /*********************** LOGOUT **********************/
        logout(context, data) {
            // On post sur le chemin de l'api de login, ave les datas en 2e (qu'on a passées à l'appel de la fonction), puis les headers
            return new Promise ((resolve, reject) => {
                api.post(process.env.VUE_APP_API + 'logout', data)
                    .then(() => {
                        // On lance la mutation pour reset l'utilisateur
                        context.commit('resetUser')
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** RECUPERATION DES MOYENS DE PAIEMENT **********************/
        paymentMethods() {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            return new Promise ((resolve, reject) => {
                api.get(process.env.VUE_APP_API + 'paymentmethod')
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** MODIF MOYEN DE PAIEMENT **********************/
        updatePaymentMethods(context, data) {
            let formData = new FormData();
            formData.append('id', data.id);
            formData.append('name', data.name);
            formData.append('logo', data.logo);
            formData.append('default', data.default);
            // Bug php de base, on peut pas multipart/formdata avec un put, donc on lance la requete en post, avec un _method put dans le formdata
            formData.append('_method', 'put');

            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            return new Promise ((resolve, reject) => {
                
                apiWithFile.post(process.env.VUE_APP_API + 'paymentmethod/' + data.id, formData)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** STORE MOYEN DE PAIEMENT **********************/
        storePaymentMethods(context, data) {
            let formData = new FormData();
            formData.append('name', data.name);
            formData.append('logo', data.logo);
            data.default === true ? formData.append('default', 1) : formData.append('default', 0)

            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            return new Promise ((resolve, reject) => {
                
                apiWithFile.post(process.env.VUE_APP_API + 'paymentmethod', formData)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** DELETE MOYEN DE PAIEMENT **********************/
        deletePaymentMethods(context, data) {

            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            return new Promise ((resolve, reject) => {
                
                api.delete(process.env.VUE_APP_API + 'paymentmethod/' + data.id)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** RECUPERATION DES CATEGORIES **********************/
        categories() {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            return new Promise ((resolve, reject) => {
                api.get(process.env.VUE_APP_API + 'category')
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** MODIF CATEGORIE **********************/
        updateCategory(context, data) {

            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            return new Promise ((resolve, reject) => {
                
                api.put(process.env.VUE_APP_API + 'category/' + data.id, data)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** STORE CATEGORIE **********************/
        storeCategory(context, data) {

            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            return new Promise ((resolve, reject) => {
                
                api.post(process.env.VUE_APP_API + 'category', data)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** DELETE CATEGORIE **********************/
        deleteCategory(context, data) {

            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            return new Promise ((resolve, reject) => {
                
                api.delete(process.env.VUE_APP_API + 'category/' + data.id)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** RECUPERATION DES COMPTES **********************/
        accounts(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.get(process.env.VUE_APP_API + 'account/' + data.year + '/' + data.month + '?show=' + data.hided)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** SHOW DU COMPTE **********************/
        showAccount(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.get(process.env.VUE_APP_API + 'account/' + data.id + '/' + data.year + '/' + data.month)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** STORE DU COMPTE **********************/
        createAccount(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.post(process.env.VUE_APP_API + 'account', data)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** DELETE DU COMPTE **********************/
        deleteAccount(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.delete(process.env.VUE_APP_API + 'account/' + data.id)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** MODIF OPERATION **********************/
        updateOperation(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            return new Promise ((resolve, reject) => {
                api.put(process.env.VUE_APP_API + 'operation/' + data.id, data)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** STORE OPERATION **********************/
        createOperation(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.post(process.env.VUE_APP_API + 'operation', data)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** DELETE OPERATION **********************/
        deleteOperation(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.delete(process.env.VUE_APP_API + 'operation/' + data.id)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** RECUPERATION DES RECURRENCES UTILISEES PAR LE USER **********************/
        recurrences() {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.get(process.env.VUE_APP_API + 'recurrence')
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** RECUPERATION DES DIFFERENTES RECURRENCES EXISTANTES **********************/
        recurrences_names() {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.get(process.env.VUE_APP_API + 'recurrencename')
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** STORE RECURRENCE **********************/
        createRecurrence(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.post(process.env.VUE_APP_API + 'recurrence', data)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** SHOW RECURRENCE **********************/
        showRecurrence(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.get(process.env.VUE_APP_API + 'recurrence/' + data.id)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** RECUPERATION DES DEBTS USERS **********************/
        debtUsers() {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.get(process.env.VUE_APP_API + 'debtuser')
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** MODIF DEBTS USER **********************/
        updateDebtUser(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            return new Promise ((resolve, reject) => {
                api.put(process.env.VUE_APP_API + 'debtuser/' + data.id, data)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** STORE DEBTS USER **********************/
        createDebtUser(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.post(process.env.VUE_APP_API + 'debtuser', data)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** DELETE DEBTS USER **********************/
        deleteDebtUser(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.delete(process.env.VUE_APP_API + 'debtuser/' + data.id)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** RECUPERATION DES DETTES **********************/
        debts() {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.get(process.env.VUE_APP_API + 'debt')
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** MODIF DEBT **********************/
        updateDebt(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            return new Promise ((resolve, reject) => {
                api.put(process.env.VUE_APP_API + 'debt/' + data.id, data)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** STORE DEBT **********************/
        createDebt(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.post(process.env.VUE_APP_API + 'debt', data)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** DELETE DEBT **********************/
        deleteDebt(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.delete(process.env.VUE_APP_API + 'debt/' + data.id)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** SHOW DEBT **********************/
        showDebt(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.get(process.env.VUE_APP_API + 'debt/' + data.id)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** MODIF USER **********************/
        updateUser(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            return new Promise ((resolve, reject) => {
                api.put(process.env.VUE_APP_API + 'user/' + data.id, data)
                    .then((response) => {
                        // On lance la mutation pour set les informations utilisateur
                        context.commit('setUserInfos', response.data)
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** DELETE USER **********************/
        deleteUser(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.delete(process.env.VUE_APP_API + 'user/' + data.id, data)
                    .then((response) => {
                        // On lance la mutation pour reset l'utilisateur
                        context.commit('resetUser')
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },

        /*********************** MIN DATE WITH OPERATION ID **********************/
        minDateByOperation(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            return new Promise ((resolve, reject) => {
                api.get(process.env.VUE_APP_API + 'minDateByOperation/' + data.id)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** RESET PASSWORD **********************/
        sendMailResetPassword(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.post(process.env.VUE_APP_API + 'password/email', data)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** RESET PASSWORD **********************/
        resetPassword(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.post(process.env.VUE_APP_API + 'password/reset', data)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
        
        /*********************** UPDATE BANK RECORD **********************/
        bankRecord(context, data) {
            // On retourne une promise, pour pouvoir accès aux datas dans les components (avec resolve et reject)
            // On veut prendre toutes les opérations du mois/année selectionnés
            return new Promise ((resolve, reject) => {
                api.post(process.env.VUE_APP_API + 'operation/bankRecord/' + data.id, data)
                    .then((response) => {
                        // On retourne la response
                        resolve(response)
                    }).catch((error) => {
                        // Si une erreur est retournée, on la renvoie a la vue
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                // On retourne l'erreur
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
            })
        },
    }
})