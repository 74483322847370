<template>
    <div class="modalContainer">
        <div class="modalContent">
            <div class="modalCloseCross" @click="$emit('close')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="currentColor"
                  class="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
            </div>

            <!-- EDIT - CREATE -->
            <form @submit.prevent="submitForm" method="post" v-if="mode === 'edit' || mode === 'create'">
                <div class="field">
                    <div class="fieldTitle">
                        <span>Nom de l'utilisateur</span>
                    </div>
                    <div class="fieldBody">
                        <input type="text" placeholder="Ex: Jean Dupont" v-model="debtUserName"/>
                    </div>
                    <span class="errorMessage" v-if="userError">Vous devez définir un nom d'utilisateur.</span>
                </div>

                <div class="modalButtons">
                    <button  class="validationButton"> 
                        <input type="submit" value="Valider"/>
                    </button>
                    <button class="deleteButton" @click="mode = 'delete'" v-if="mode === 'edit'">Supprimer</button>
                    <button class="modalCancelButton" @click="$emit('close')">Annuler</button>
                </div>
            </form>
            
            <!-- DELETE -->
            <div class="contentShow" v-if="mode === 'delete'">
                <p>
                    <b>Toutes les liaisons à cet utilisateur seront irrémédiablement perdues. Les opérations seront conservées, mais ne seront plus indiquées comme étant liées à cet utilisateur.</b><br/>
                    Validez la suppression de l'utilisateur <b>"{{ debtUserName }}"</b> en entrant les mots "{{ confirmationTemplate }}".
                </p>

                <div class="field">
                    <div class="fieldTitle">
                        <span>Confirmation</span>
                    </div>
                    <div class="fieldBody">
                        <input type="text" :placeholder="confirmationTemplate" v-model="confirmation"/>
                    </div>
                    <span class="errorMessage" v-if="confirmationError">Veuillez confirmer la suppression en entrant les mots "{{ confirmationTemplate }}"</span>
                </div>

                <div class="modalButtons">
                    <button class="modalCancelButton" @click="$emit('close')">Annuler</button>
                    <button class="deleteButton" @click="submitForm">Supprimer</button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    props: [
        'modeProp',
        'debtUserIDProp',
        'debtUserNameProp',
    ],
    data () {
        return {
            // On défini des variables qui prenent la valeur des props, puisqu'elles seront sujettes à modifications
            mode: this.modeProp,
            debtUserID: this.debtUserIDProp,
            debtUserName: this.debtUserNameProp,
            confirmationTemplate: 'Je confirme',
            confirmation: '',
            confirmationError: false,
            userError: false
        }
    },
    computed: {
        ...mapGetters({
            // On importe les données utilisateur
            user: "getUser",
        }),
    },
    methods: {
        ...mapActions({
            updateDebtUser: "updateDebtUser",
            storeDebtUser: "createDebtUser",
            deleteDebtUser: "deleteDebtUser"
        }),
        submitForm() {
            if(this.mode === 'delete') {
                if(this.confirmation === this.confirmationTemplate) {
                    // On fait appel l'action delete
                    this.deleteDebtUser({
                        id: this.debtUserID
                        // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                    }).then(() => {
                        let toast = (
                            <div>
                                <span>L'utilisateur a bien été supprimé.</span>
                            </div>
                        )
                        this.$toast.success(toast)
                        this.$emit('close')
                        // Et ici l'erreur, envoyée par reject
                    }).catch(error => {
                        this.$toast.warning(error)
                    })
                } else {
                    this.confirmationError = true
                }
            }

            /********** VALIDATION DES DONNEES ************/
            this.debtUserName === null || this.debtUserName === '' ? this.userError = true : this.userError = false

            // On traite les données entrées 
            if (!this.userError) {
                if(this.mode === 'edit') {
                    // On fait appel l'action update
                    this.updateDebtUser({
                        id: this.debtUserID,
                        name: this.debtUserName,
                        // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                    }).then(() => {
                        let toast = (
                            <div>
                                <span>L'utilisateur a bien été mis à jour.</span>
                            </div>
                        )
                        this.$toast.success(toast)
                        this.$emit('close')
                        // Et ici l'erreur, envoyée par reject
                    }).catch(error => {
                        this.$toast.warning(error)
                    })
                } else if (this.mode === 'create') {
                    // On fait appel l'action store
                    this.storeDebtUser({
                        name: this.debtUserName,
                        // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                    }).then(() => {
                        let toast = (
                            <div>
                                <span>L'utilisateur a bien été créé.</span>
                            </div>
                        )
                        this.$toast.success(toast)
                        this.$emit('close')
                        // Et ici l'erreur, envoyée par reject
                    }).catch(error => {
                        this.$toast.warning(error)
                    })
                }
            }
        }
    },
}
</script>

<style scoped>
.field {
    margin-top: 0;
}

/* Tous les .field qui sont après des .field */
.field ~ .field {
    margin-top: 45px;
}

.fieldBodyCategory {
    color: white;
}

.fieldBodyCategory > * {
    padding-left: 10px;
}
</style>
