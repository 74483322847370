<template>
    <div class="page">
        <!-- Si l'utilisateur n'est pas un admin -->
        <div v-if="!user.isOnline">
            <p>Vous devez être connecté pour consulter cette page</p>
        </div>

        <div v-else>
            <div class="sectionTitle">
                <span>Général</span>
            </div>

            <div>
                <center>
                    <!-- Si on a pas d'accout spécifié, on cache le button de delete account -->
                    <div class="semiColons">
                        <button class="button smallButton" @click="activateDebtUserComponent('create')">
                            <span>Ajouter un utilisateur</span>
                        </button>
                    </div>
                </center>
            </div>

            <div class="field" v-for="debtUser in debtUsers" :key="debtUser.id">
                <div class="fieldTitle">
                    <span>{{ debtUser.name }}</span>
                </div>
                <div class="fieldBody recap">
                    <div>
                        <div>
                            <span>Prêt en cours</span>
                            <span class="positiveAmount">{{ positiveAmount(debtUser.debts) }} €</span>
                        </div>
                        <div>
                            <span>Emprunt en cours</span>
                            <span class="negativeAmount">{{ negativeAmount(debtUser.debts) }} €</span>
                        </div>
                    </div>
                    <button @click="activateDebtUserComponent('edit', debtUser.id, debtUser.name)">Modifier l'utilisateur</button>
                </div>
            </div>
            
            <DebtUser
                v-if="showDebtUserModal"
                :modeProp = modeProp
                :debtUserIDProp = debtUserIDProp
                :debtUserNameProp = debtUserNameProp
                @close="showDebtUserModal = false; refreshDebtUsers()"
            />
        </div>
    </div>
</template>

<style scoped>
button {
    margin-top: 25px;
    width: 100%
}

.field {
    height: initial;
    margin-top: 40px;
}

.fieldBody {
    height: initial;
    padding-top: 25px;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.recap > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.recap > div > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.semiColons > .button {
  width: 45%;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import DebtUser from '../components/DebtUserComponent.vue'

export default {
    data () {
        return {
            debtUsers: null,
            modeProp: '',
            debtUserIDProp: null,
            debtUserNameProp: null,
            showDebtUserModal: false,
        }
    },
    computed: {
        ...mapGetters({
            // On importe les données utilisateur
            user: "getUser",
        }),
    },
    components: {
        DebtUser,
    },
    methods: {
        ...mapActions({
            getDebtUsers: "debtUsers",
        }),
        refreshDebtUsers() {
            this.getDebtUsers()
                .then(response => {
                    this.debtUsers = response.data
                })
                .catch(error => {
                    this.$toast.warning(error)
                })
        },
        // Calcul des dettes positives sur demande. Pas de variable pour le component, il faudra le faire pour chaque debtuser
        positiveAmount(debts) {
            // On init la valeur qu'on retournera
            let amount = 0
            for (let debt of debts) {
                // Si on a un montant positif et que la dette n'est pas remboursée
                if (debt.amount > 0 && debt.refunded === 0) {
                    amount += debt.amount
                }
            }
            return amount
        },
        // Calcul des dettes negatives sur demande. Pas de variable pour le component, il faudra le faire pour chaque debtuser
        negativeAmount(debts) {
            // On init la valeur qu'on retournera
            let amount = 0
            for (let debt of debts) {
                // Si on a un montant positif et que la dette n'est pas remboursée
                if (debt.amount < 0 && debt.refunded === 0) {
                    amount -= debt.amount
                }
            }
            return amount
        },
        activateDebtUserComponent(mode, id = null, name = null) {
            this.showDebtUserModal = true
            this.modeProp = mode
            this.debtUserIDProp = id
            this.debtUserNameProp = name
        },
    },
    mounted() {
        this.refreshDebtUsers()
    },
};
</script>