<template>
  <div id="app">
    <div id="header">
      <div id="logo">
        <img alt="Vue logo" src="./assets/logo.png" />
      </div>
      <div id="pageName">Accueil</div>
      <div class="menu">
        <div class="mobile" v-on:click="showMenu = !showMenu">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            class="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            ></path>
          </svg>
          <!-- On ajoute une animation de transition -->
          <transition name="menu">
            <div v-if="showMenu" class="firstLevel" id="firstLevel">
              <div class="closeCross">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="currentColor"
                  class="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </div>
              <!-- On calcule 100% de la fenetre, - les 60% de la croix -->
              <ul class="links" :style="{ height: `calc(100% - 60px)` }">
                <!-- ********* START LINK SECTION ********* -->
                <div v-if="!user.isOnline">
                  <li>
                    <router-link
                      :to="{ name: 'Connection' }"
                      class="menuLink"
                      style="text-decoration: none; color: #f0f0f0"
                      >Connexion</router-link
                    >
                  </li>
                  <div class="separation">
                    <hr class="menuSeparator" />
                    <!-- On calcule la position du diamant, 50% de la case - la moitié de sa longueur/largeur -->
                    <svg
                      class="diamond"
                      :style="{ top: `calc(50% - 6px)`, left: `calc(50% - 8px)` }"
                    >
                      <path
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.134 5.15625C0.0462315 5.26076 2.52724e-05 5.37932 2.52724e-05 5.5C2.52724e-05 5.62068 0.0462315 5.73924 0.134 5.84375L3.711 9.96875C3.79877 10.0733 3.925 10.16 4.07701 10.2204C4.22903 10.2807 4.40147 10.3125 4.577 10.3125H11.423C11.5985 10.3125 11.771 10.2807 11.923 10.2204C12.075 10.16 12.2012 10.0733 12.289 9.96875L15.866 5.84375C15.9538 5.73924 16 5.62068 16 5.5C16 5.37932 15.9538 5.26076 15.866 5.15625L12.289 1.03125C12.2012 0.92674 12.075 0.839952 11.923 0.779612C11.771 0.719272 11.5985 0.687503 11.423 0.6875H4.577C4.40147 0.687503 4.22903 0.719272 4.07701 0.779612C3.925 0.839952 3.79877 0.92674 3.711 1.03125L0.134 5.15625Z"
                        fill="#F0F0F0"
                      />
                    </svg>
                  </div>
                </div>
                <!-- ********* END LINK SECTION ********* -->
                <!-- ********* START LINK SECTION ********* -->
                <div v-if="!user.isOnline">
                  <li>
                    <router-link
                      :to="{ name: 'Register' }"
                      class="menuLink"
                      style="text-decoration: none; color: #f0f0f0"
                      >Inscription</router-link
                    >
                  </li>
                  <div class="separation">
                    <hr class="menuSeparator" />
                    <!-- On calcule la position du diamant, 50% de la case - la moitié de sa longueur/largeur -->
                    <svg
                      class="diamond"
                      :style="{ top: `calc(50% - 6px)`, left: `calc(50% - 8px)` }"
                    >
                      <path
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.134 5.15625C0.0462315 5.26076 2.52724e-05 5.37932 2.52724e-05 5.5C2.52724e-05 5.62068 0.0462315 5.73924 0.134 5.84375L3.711 9.96875C3.79877 10.0733 3.925 10.16 4.07701 10.2204C4.22903 10.2807 4.40147 10.3125 4.577 10.3125H11.423C11.5985 10.3125 11.771 10.2807 11.923 10.2204C12.075 10.16 12.2012 10.0733 12.289 9.96875L15.866 5.84375C15.9538 5.73924 16 5.62068 16 5.5C16 5.37932 15.9538 5.26076 15.866 5.15625L12.289 1.03125C12.2012 0.92674 12.075 0.839952 11.923 0.779612C11.771 0.719272 11.5985 0.687503 11.423 0.6875H4.577C4.40147 0.687503 4.22903 0.719272 4.07701 0.779612C3.925 0.839952 3.79877 0.92674 3.711 1.03125L0.134 5.15625Z"
                        fill="#F0F0F0"
                      />
                    </svg>
                  </div>
                </div>
                <!-- ********* END LINK SECTION ********* -->
                <!-- ********* START LINK SECTION ********* -->
                <div v-if="user.isOnline">
                  <li>
                    <a
                      href=""
                      title="Déconnexion"
                      class="menuLink"
                      style="text-decoration: none; color: #f0f0f0"
                      @click.prevent="disconnect"
                      >Déconnexion</a
                    >
                  </li>
                  <div class="separation">
                    <hr class="menuSeparator" />
                    <!-- On calcule la position du diamant, 50% de la case - la moitié de sa longueur/largeur -->
                    <svg
                      class="diamond"
                      :style="{ top: `calc(50% - 6px)`, left: `calc(50% - 8px)` }"
                    >
                      <path
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.134 5.15625C0.0462315 5.26076 2.52724e-05 5.37932 2.52724e-05 5.5C2.52724e-05 5.62068 0.0462315 5.73924 0.134 5.84375L3.711 9.96875C3.79877 10.0733 3.925 10.16 4.07701 10.2204C4.22903 10.2807 4.40147 10.3125 4.577 10.3125H11.423C11.5985 10.3125 11.771 10.2807 11.923 10.2204C12.075 10.16 12.2012 10.0733 12.289 9.96875L15.866 5.84375C15.9538 5.73924 16 5.62068 16 5.5C16 5.37932 15.9538 5.26076 15.866 5.15625L12.289 1.03125C12.2012 0.92674 12.075 0.839952 11.923 0.779612C11.771 0.719272 11.5985 0.687503 11.423 0.6875H4.577C4.40147 0.687503 4.22903 0.719272 4.07701 0.779612C3.925 0.839952 3.79877 0.92674 3.711 1.03125L0.134 5.15625Z"
                        fill="#F0F0F0"
                      />
                    </svg>
                  </div>
                </div>
                <!-- ********* END LINK SECTION ********* -->
                <!-- ********* START LINK SECTION ********* -->
                <div v-if="user.isAdmin">
                  <li>
                    <router-link :to="{ name: 'WebsiteConfig' }" class="menuLink" style="text-decoration: none; color: #F0F0F0;">Configuration du site</router-link>
                  </li>
                  <div class="separation">
                    <hr class="menuSeparator">
                    <!-- On calcule la position du diamant, 50% de la case - la moitié de sa longueur/largeur -->
                    <svg class="diamond" :style="{'top': `calc(50% - 6px)`, 'left': `calc(50% - 8px)`}">
                      <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M0.134 5.15625C0.0462315 5.26076 2.52724e-05 5.37932 2.52724e-05 5.5C2.52724e-05 5.62068 0.0462315 5.73924 0.134 5.84375L3.711 9.96875C3.79877 10.0733 3.925 10.16 4.07701 10.2204C4.22903 10.2807 4.40147 10.3125 4.577 10.3125H11.423C11.5985 10.3125 11.771 10.2807 11.923 10.2204C12.075 10.16 12.2012 10.0733 12.289 9.96875L15.866 5.84375C15.9538 5.73924 16 5.62068 16 5.5C16 5.37932 15.9538 5.26076 15.866 5.15625L12.289 1.03125C12.2012 0.92674 12.075 0.839952 11.923 0.779612C11.771 0.719272 11.5985 0.687503 11.423 0.6875H4.577C4.40147 0.687503 4.22903 0.719272 4.07701 0.779612C3.925 0.839952 3.79877 0.92674 3.711 1.03125L0.134 5.15625Z" fill="#F0F0F0"/>
                    </svg>
                  </div>
                </div>
                <!-- ********* END LINK SECTION ********* -->
                <!-- ********* START LINK SECTION ********* -->
                <div v-if="user.isOnline">
                  <li>
                    <router-link :to="{ name: 'Accounts' }" class="menuLink" style="text-decoration: none; color: #F0F0F0;">Mes comptes</router-link>
                  </li>
                  <div class="separation">
                    <hr class="menuSeparator">
                    <!-- On calcule la position du diamant, 50% de la case - la moitié de sa longueur/largeur -->
                    <svg class="diamond" :style="{'top': `calc(50% - 6px)`, 'left': `calc(50% - 8px)`}">
                      <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M0.134 5.15625C0.0462315 5.26076 2.52724e-05 5.37932 2.52724e-05 5.5C2.52724e-05 5.62068 0.0462315 5.73924 0.134 5.84375L3.711 9.96875C3.79877 10.0733 3.925 10.16 4.07701 10.2204C4.22903 10.2807 4.40147 10.3125 4.577 10.3125H11.423C11.5985 10.3125 11.771 10.2807 11.923 10.2204C12.075 10.16 12.2012 10.0733 12.289 9.96875L15.866 5.84375C15.9538 5.73924 16 5.62068 16 5.5C16 5.37932 15.9538 5.26076 15.866 5.15625L12.289 1.03125C12.2012 0.92674 12.075 0.839952 11.923 0.779612C11.771 0.719272 11.5985 0.687503 11.423 0.6875H4.577C4.40147 0.687503 4.22903 0.719272 4.07701 0.779612C3.925 0.839952 3.79877 0.92674 3.711 1.03125L0.134 5.15625Z" fill="#F0F0F0"/>
                    </svg>
                  </div>
                </div>
                <!-- ********* END LINK SECTION ********* -->
                <!-- ********* START LINK SECTION ********* -->
                <div v-if="user.isOnline">
                  <li>
                    <router-link :to="{ name: 'DebtUsers' }" class="menuLink" style="text-decoration: none; color: #F0F0F0;">Utilisateurs</router-link>
                  </li>
                  <div class="separation">
                    <hr class="menuSeparator">
                    <!-- On calcule la position du diamant, 50% de la case - la moitié de sa longueur/largeur -->
                    <svg class="diamond" :style="{'top': `calc(50% - 6px)`, 'left': `calc(50% - 8px)`}">
                      <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M0.134 5.15625C0.0462315 5.26076 2.52724e-05 5.37932 2.52724e-05 5.5C2.52724e-05 5.62068 0.0462315 5.73924 0.134 5.84375L3.711 9.96875C3.79877 10.0733 3.925 10.16 4.07701 10.2204C4.22903 10.2807 4.40147 10.3125 4.577 10.3125H11.423C11.5985 10.3125 11.771 10.2807 11.923 10.2204C12.075 10.16 12.2012 10.0733 12.289 9.96875L15.866 5.84375C15.9538 5.73924 16 5.62068 16 5.5C16 5.37932 15.9538 5.26076 15.866 5.15625L12.289 1.03125C12.2012 0.92674 12.075 0.839952 11.923 0.779612C11.771 0.719272 11.5985 0.687503 11.423 0.6875H4.577C4.40147 0.687503 4.22903 0.719272 4.07701 0.779612C3.925 0.839952 3.79877 0.92674 3.711 1.03125L0.134 5.15625Z" fill="#F0F0F0"/>
                    </svg>
                  </div>
                </div>
                <!-- ********* END LINK SECTION ********* -->
                <!-- ********* START LINK SECTION ********* -->
                <div v-if="user.isOnline">
                  <li>
                    <router-link :to="{ name: 'Debts' }" class="menuLink" style="text-decoration: none; color: #F0F0F0;">Dettes</router-link>
                  </li>
                  <div class="separation">
                    <hr class="menuSeparator">
                    <!-- On calcule la position du diamant, 50% de la case - la moitié de sa longueur/largeur -->
                    <svg class="diamond" :style="{'top': `calc(50% - 6px)`, 'left': `calc(50% - 8px)`}">
                      <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M0.134 5.15625C0.0462315 5.26076 2.52724e-05 5.37932 2.52724e-05 5.5C2.52724e-05 5.62068 0.0462315 5.73924 0.134 5.84375L3.711 9.96875C3.79877 10.0733 3.925 10.16 4.07701 10.2204C4.22903 10.2807 4.40147 10.3125 4.577 10.3125H11.423C11.5985 10.3125 11.771 10.2807 11.923 10.2204C12.075 10.16 12.2012 10.0733 12.289 9.96875L15.866 5.84375C15.9538 5.73924 16 5.62068 16 5.5C16 5.37932 15.9538 5.26076 15.866 5.15625L12.289 1.03125C12.2012 0.92674 12.075 0.839952 11.923 0.779612C11.771 0.719272 11.5985 0.687503 11.423 0.6875H4.577C4.40147 0.687503 4.22903 0.719272 4.07701 0.779612C3.925 0.839952 3.79877 0.92674 3.711 1.03125L0.134 5.15625Z" fill="#F0F0F0"/>
                    </svg>
                  </div>
                </div>
                <!-- ********* END LINK SECTION ********* -->
                <!-- ********* START LINK SECTION ********* -->
                <div v-if="user.isOnline">
                  <li>
                    <router-link :to="{ name: 'User' }" class="menuLink" style="text-decoration: none; color: #F0F0F0;">Mon compte</router-link>
                  </li>
                  <div class="separation">
                    <hr class="menuSeparator">
                    <!-- On calcule la position du diamant, 50% de la case - la moitié de sa longueur/largeur -->
                    <svg class="diamond" :style="{'top': `calc(50% - 6px)`, 'left': `calc(50% - 8px)`}">
                      <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M0.134 5.15625C0.0462315 5.26076 2.52724e-05 5.37932 2.52724e-05 5.5C2.52724e-05 5.62068 0.0462315 5.73924 0.134 5.84375L3.711 9.96875C3.79877 10.0733 3.925 10.16 4.07701 10.2204C4.22903 10.2807 4.40147 10.3125 4.577 10.3125H11.423C11.5985 10.3125 11.771 10.2807 11.923 10.2204C12.075 10.16 12.2012 10.0733 12.289 9.96875L15.866 5.84375C15.9538 5.73924 16 5.62068 16 5.5C16 5.37932 15.9538 5.26076 15.866 5.15625L12.289 1.03125C12.2012 0.92674 12.075 0.839952 11.923 0.779612C11.771 0.719272 11.5985 0.687503 11.423 0.6875H4.577C4.40147 0.687503 4.22903 0.719272 4.07701 0.779612C3.925 0.839952 3.79877 0.92674 3.711 1.03125L0.134 5.15625Z" fill="#F0F0F0"/>
                    </svg>
                  </div>
                </div>
                <!-- ********* END LINK SECTION ********* -->
              </ul>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="desktop">
      <ul>
        <li v-if="!user.isOnline">
          <router-link
            :to="{ name: 'Connection' }"
            style="text-decoration: none; color: #f0f0f0"
            >Connexion</router-link
          >
        </li>
        <li v-if="!user.isOnline">
          <svg class="diamond" :style="{'top': `calc(50% - 6px)`}">
            <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M0.134 5.15625C0.0462315 5.26076 2.52724e-05 5.37932 2.52724e-05 5.5C2.52724e-05 5.62068 0.0462315 5.73924 0.134 5.84375L3.711 9.96875C3.79877 10.0733 3.925 10.16 4.07701 10.2204C4.22903 10.2807 4.40147 10.3125 4.577 10.3125H11.423C11.5985 10.3125 11.771 10.2807 11.923 10.2204C12.075 10.16 12.2012 10.0733 12.289 9.96875L15.866 5.84375C15.9538 5.73924 16 5.62068 16 5.5C16 5.37932 15.9538 5.26076 15.866 5.15625L12.289 1.03125C12.2012 0.92674 12.075 0.839952 11.923 0.779612C11.771 0.719272 11.5985 0.687503 11.423 0.6875H4.577C4.40147 0.687503 4.22903 0.719272 4.07701 0.779612C3.925 0.839952 3.79877 0.92674 3.711 1.03125L0.134 5.15625Z" fill="#F0F0F0"/>
          </svg>
        </li>
        <li v-if="!user.isOnline">
          <router-link
            :to="{ name: 'Register' }"
            style="text-decoration: none; color: #f0f0f0"
            >Inscription</router-link
          >
        </li>
        <li v-if="user.isOnline">
          <a
            href=""
            title="Déconnexion"
            style="text-decoration: none; color: #f0f0f0"
            @click.prevent="disconnect"
            >Déconnexion</a
          >
        </li>
        <li v-if="user.isOnline">
          <svg class="diamond" :style="{'top': `calc(50% - 6px)`}">
            <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M0.134 5.15625C0.0462315 5.26076 2.52724e-05 5.37932 2.52724e-05 5.5C2.52724e-05 5.62068 0.0462315 5.73924 0.134 5.84375L3.711 9.96875C3.79877 10.0733 3.925 10.16 4.07701 10.2204C4.22903 10.2807 4.40147 10.3125 4.577 10.3125H11.423C11.5985 10.3125 11.771 10.2807 11.923 10.2204C12.075 10.16 12.2012 10.0733 12.289 9.96875L15.866 5.84375C15.9538 5.73924 16 5.62068 16 5.5C16 5.37932 15.9538 5.26076 15.866 5.15625L12.289 1.03125C12.2012 0.92674 12.075 0.839952 11.923 0.779612C11.771 0.719272 11.5985 0.687503 11.423 0.6875H4.577C4.40147 0.687503 4.22903 0.719272 4.07701 0.779612C3.925 0.839952 3.79877 0.92674 3.711 1.03125L0.134 5.15625Z" fill="#F0F0F0"/>
          </svg>
        </li>
        <li v-if="user.isAdmin">
          <router-link :to="{ name: 'WebsiteConfig' }" class="menuLink" style="text-decoration: none; color: #F0F0F0;">Configuration du site</router-link>
        </li>
        <li v-if="user.isAdmin">
          <svg class="diamond" :style="{'top': `calc(50% - 6px)`}">
            <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M0.134 5.15625C0.0462315 5.26076 2.52724e-05 5.37932 2.52724e-05 5.5C2.52724e-05 5.62068 0.0462315 5.73924 0.134 5.84375L3.711 9.96875C3.79877 10.0733 3.925 10.16 4.07701 10.2204C4.22903 10.2807 4.40147 10.3125 4.577 10.3125H11.423C11.5985 10.3125 11.771 10.2807 11.923 10.2204C12.075 10.16 12.2012 10.0733 12.289 9.96875L15.866 5.84375C15.9538 5.73924 16 5.62068 16 5.5C16 5.37932 15.9538 5.26076 15.866 5.15625L12.289 1.03125C12.2012 0.92674 12.075 0.839952 11.923 0.779612C11.771 0.719272 11.5985 0.687503 11.423 0.6875H4.577C4.40147 0.687503 4.22903 0.719272 4.07701 0.779612C3.925 0.839952 3.79877 0.92674 3.711 1.03125L0.134 5.15625Z" fill="#F0F0F0"/>
          </svg>
        </li>
        <li v-if="user.isOnline">
          <router-link :to="{ name: 'Accounts' }" class="menuLink" style="text-decoration: none; color: #F0F0F0;">Mes comptes</router-link>
        </li>
        <li v-if="user.isOnline">
          <svg class="diamond" :style="{'top': `calc(50% - 6px)`}">
            <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M0.134 5.15625C0.0462315 5.26076 2.52724e-05 5.37932 2.52724e-05 5.5C2.52724e-05 5.62068 0.0462315 5.73924 0.134 5.84375L3.711 9.96875C3.79877 10.0733 3.925 10.16 4.07701 10.2204C4.22903 10.2807 4.40147 10.3125 4.577 10.3125H11.423C11.5985 10.3125 11.771 10.2807 11.923 10.2204C12.075 10.16 12.2012 10.0733 12.289 9.96875L15.866 5.84375C15.9538 5.73924 16 5.62068 16 5.5C16 5.37932 15.9538 5.26076 15.866 5.15625L12.289 1.03125C12.2012 0.92674 12.075 0.839952 11.923 0.779612C11.771 0.719272 11.5985 0.687503 11.423 0.6875H4.577C4.40147 0.687503 4.22903 0.719272 4.07701 0.779612C3.925 0.839952 3.79877 0.92674 3.711 1.03125L0.134 5.15625Z" fill="#F0F0F0"/>
          </svg>
        </li>
        <li v-if="user.isOnline">
          <router-link :to="{ name: 'DebtUsers' }" class="menuLink" style="text-decoration: none; color: #F0F0F0;">Utilisateurs</router-link>
        </li>
        <li v-if="user.isOnline">
          <svg class="diamond" :style="{'top': `calc(50% - 6px)`}">
            <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M0.134 5.15625C0.0462315 5.26076 2.52724e-05 5.37932 2.52724e-05 5.5C2.52724e-05 5.62068 0.0462315 5.73924 0.134 5.84375L3.711 9.96875C3.79877 10.0733 3.925 10.16 4.07701 10.2204C4.22903 10.2807 4.40147 10.3125 4.577 10.3125H11.423C11.5985 10.3125 11.771 10.2807 11.923 10.2204C12.075 10.16 12.2012 10.0733 12.289 9.96875L15.866 5.84375C15.9538 5.73924 16 5.62068 16 5.5C16 5.37932 15.9538 5.26076 15.866 5.15625L12.289 1.03125C12.2012 0.92674 12.075 0.839952 11.923 0.779612C11.771 0.719272 11.5985 0.687503 11.423 0.6875H4.577C4.40147 0.687503 4.22903 0.719272 4.07701 0.779612C3.925 0.839952 3.79877 0.92674 3.711 1.03125L0.134 5.15625Z" fill="#F0F0F0"/>
          </svg>
        </li>
        <li v-if="user.isOnline">
          <router-link :to="{ name: 'Debts' }" class="menuLink" style="text-decoration: none; color: #F0F0F0;">Dettes</router-link>
        </li>
        <li v-if="user.isOnline">
          <svg class="diamond" :style="{'top': `calc(50% - 6px)`}">
            <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M0.134 5.15625C0.0462315 5.26076 2.52724e-05 5.37932 2.52724e-05 5.5C2.52724e-05 5.62068 0.0462315 5.73924 0.134 5.84375L3.711 9.96875C3.79877 10.0733 3.925 10.16 4.07701 10.2204C4.22903 10.2807 4.40147 10.3125 4.577 10.3125H11.423C11.5985 10.3125 11.771 10.2807 11.923 10.2204C12.075 10.16 12.2012 10.0733 12.289 9.96875L15.866 5.84375C15.9538 5.73924 16 5.62068 16 5.5C16 5.37932 15.9538 5.26076 15.866 5.15625L12.289 1.03125C12.2012 0.92674 12.075 0.839952 11.923 0.779612C11.771 0.719272 11.5985 0.687503 11.423 0.6875H4.577C4.40147 0.687503 4.22903 0.719272 4.07701 0.779612C3.925 0.839952 3.79877 0.92674 3.711 1.03125L0.134 5.15625Z" fill="#F0F0F0"/>
          </svg>
        </li>
        <li v-if="user.isOnline">
          <router-link :to="{ name: 'User' }" class="menuLink" style="text-decoration: none; color: #F0F0F0;">Mon compte</router-link>
        </li>
      </ul>
    </div>
    <svg
      class="diamond diamondMobile"
      :style="{ top: `calc(60px - 6px)`, left: `calc(50% - 8px)` }"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.134 5.15625C0.0462315 5.26076 2.52724e-05 5.37932 2.52724e-05 5.5C2.52724e-05 5.62068 0.0462315 5.73924 0.134 5.84375L3.711 9.96875C3.79877 10.0733 3.925 10.16 4.07701 10.2204C4.22903 10.2807 4.40147 10.3125 4.577 10.3125H11.423C11.5985 10.3125 11.771 10.2807 11.923 10.2204C12.075 10.16 12.2012 10.0733 12.289 9.96875L15.866 5.84375C15.9538 5.73924 16 5.62068 16 5.5C16 5.37932 15.9538 5.26076 15.866 5.15625L12.289 1.03125C12.2012 0.92674 12.075 0.839952 11.923 0.779612C11.771 0.719272 11.5985 0.687503 11.423 0.6875H4.577C4.40147 0.687503 4.22903 0.719272 4.07701 0.779612C3.925 0.839952 3.79877 0.92674 3.711 1.03125L0.134 5.15625Z"
        fill="#F0F0F0"
      />
    </svg>
    <!-- On donne une hauteur minimum de 100vh, pour que le footer soit bien stick en bas, même si la page est courte en hauteur -->
    <router-view class="routerView"/>
    <div id="footer">
      <svg
        class="diamond"
        :style="{ top: `calc(0px - 6px)`, left: `calc(50% - 8px)` }"
      >
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.134 5.15625C0.0462315 5.26076 2.52724e-05 5.37932 2.52724e-05 5.5C2.52724e-05 5.62068 0.0462315 5.73924 0.134 5.84375L3.711 9.96875C3.79877 10.0733 3.925 10.16 4.07701 10.2204C4.22903 10.2807 4.40147 10.3125 4.577 10.3125H11.423C11.5985 10.3125 11.771 10.2807 11.923 10.2204C12.075 10.16 12.2012 10.0733 12.289 9.96875L15.866 5.84375C15.9538 5.73924 16 5.62068 16 5.5C16 5.37932 15.9538 5.26076 15.866 5.15625L12.289 1.03125C12.2012 0.92674 12.075 0.839952 11.923 0.779612C11.771 0.719272 11.5985 0.687503 11.423 0.6875H4.577C4.40147 0.687503 4.22903 0.719272 4.07701 0.779612C3.925 0.839952 3.79877 0.92674 3.711 1.03125L0.134 5.15625Z"
          fill="#F0F0F0"
        />
      </svg>
      <div id="footerContent">
        <div id="logoFooter">
          <img alt="Vue logo" src="./assets/logo.png" />
        </div>
        <ul>
          <li>Contact</li>
          <li class="liSeparator">-</li>
          <li>Mentions légales</li>
        </ul>
      </div>
      <div id="copyright">Copyright 2021 - Thomas Herbin</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            showMenu: false,
        };
    },
    computed: {
        ...mapGetters({
            // On importe les données utilisateur
            user: "getUser",
        }),
    },
    methods: {
        ...mapActions({
            // On importe l'action de login, définie  dans le store
            logout: "logout",
        }),
        disconnect() {
            this.logout()
                .then(() => {
                    this.$toast.success('Vous êtes bien déconnecté')
                    setTimeout(()=>{
                        this.$router.push({ name: 'Connection' });
                    }, 5000);
                })
                .catch(error => {
                    this.$toast.warning(error)
                })
        },
    },
    mounted() {
      console.log(process.env)
    }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

* {
  font-family: "Roboto", sans-serif !important;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-size: 16px;
  background-color: #f0f0f0 !important;
}

ul,
li,
p {
  list-style: none;
  padding: 0;
  margin: 0 !important;
}

a,
a:hover {
  text-decoration: none;
  color: #f0f0f0;
}

/********************** HEADER ******************/

#header {
  width: 100%;
  height: 60px;
  padding: 0 20px;
  background-color: #7ca8fd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#app {
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#logo,
#logo img,
#logoFooter,
#logoFooter img {
  max-height: 40px;
}

#pageName {
  font-size: 24px;
  color: white;
}


/********************** NAVIGATION ******************/

.menu {
  display: flex;
  align-items: center;
  height: 100%;
  color: white;
}

.closeCross {
  height: 60px;
  padding: 0 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.firstLevel {
  width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #7ca8fd;
  z-index: 99999999;
}

.firstLevel:first-child {
  position: absolute;
  right: 20px;
  top: 20px;
}

.firstLevel li {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuLink {
  display: inline-block;
  width: 100%;
  font-size: 24px;
  color: #f0f0f0;
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.separation {
  position: relative;
}

.menuSeparator {
  border: 1px solid #f0f0f0;
  width: 60%;
}

.diamond {
  width: 16px;
  height: 11px;
  position: absolute;
}

.diamondMobile {
  display: block;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.desktop ul {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #6385C9;
  font-size: 24px;
  position: relative;
}

.desktop ul li{
  padding: 0 20px;
}

.routerView {
  min-height: calc(100vh - 140px); 
  position: 'relative';
}
/********************** FOOTER ******************/

#footer {
  width: 100%;
  height: 80px;
  padding: 0 30px;
  padding-top: 20px;
  position: relative;
  background-color: #7ca8fd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 16px;
  color: white;
}

#footerContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#footerContent ul {
  display: flex;
  justify-content: space-between;
}

#footerContent li {
  display: flex;
  justify-content: space-between;
}

#footerContent .liSeparator {
  padding: 0 8px;
}

#copyright {
  font-size: 10px;
}

/********************** ELEMENTS GENERAUX ******************/

input[type="date"] {
  color: #696969;
  width: auto;
}

button {
  height: 40px;
  border: none;
  background: #7ca8fd;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px !important;
  color: white;
}

input,
textarea {
  border: none;
  width: 100%;
}

input[type="submit"] {
  background: none;
  color: white;
}

input[type="file"] {
  border: none;
}

form {
  height: auto;
}

::placeholder {
  color: #a7a7a7;
}

.smallButton {
  font-size: 14px;
}

  /*************** Page *************/
.page {
  padding-top: 45px;
  padding-bottom: 45px;
  color: #696969;
  background-color: #f0f0f0;
  max-width: 820px;
  margin: auto;
}

.sectionTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectionTitle::before,
.sectionTitle::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #b2cdff;
}

.sectionTitle > span {
  padding: 0 9px;
  font-size: 20px;
}

.accounts {
  position: relative;
  max-width: 335px;
  margin: auto;
}

.accounts .icon {
  position: absolute;
  margin-left: 5px;
  margin-top: 4px;
  color: white;
}

.accountName {
  margin-top: 10px !important;
  border: none;
  background: #7ca8fd;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 25%);
  border-radius: 5px !important;
  color: white;
  cursor: pointer;
}

  /*************** Modal *************/

.modalContainer {
  /* On met en fixed, que ca couvre toute la page, et qu'on ait toujours la modal en milieu de page */
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(124, 168, 253, 0.7);
}

.modalContent {
  width: 100%;
  padding: 0 20px 20px 20px;
  min-height: 50px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  max-width: 820px;
  margin: auto;
}

.modalCloseCross {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-self: center;
  color: #7CA8FD;
}

.modalContent .field,
.modalContent form,
.modalContent .contentShow {
  width: 100%;
}

.modalButtons {
  width: 100%;
  margin-top: 35px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.modalButtons > button {
  height: 25px;
  width: 30%;
  margin: 0 5px;
}

.validationButton {
  background-color: #009F10
}

/* .modalCancelButton {
} */

.deleteButton {
  background-color: #FF4141
}

.modalInternalButton {
  display: inline-block;
  background-color: rgb(124, 168, 253);
  padding: 0 10px;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 25%);
  border-radius: 5px;
  color: white;
}

.field {
  height: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.fieldTitle {
  min-width: 20%;
  padding: 5px 10px;
  margin-left: 2%;
  text-align: left;
  font-size: 12px;
  color: #a7a7a7;
  background-color: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.fieldBody {
  padding-left: 20px;
  padding-top: 8px;
  padding-right: 20px;
  font-size: 18px;
  color: #a7a7a7;
  margin-top: 20px;
  width: 90%;
  background-color: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  text-align: left;
}

.fieldBody .read {
  color: #696969
}

.frameOptions {
    width: 125px;
    height: 30px;
    margin: 10px;
    padding: 7px;
    border-radius: 2px;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.frameOptionsReadCreate {
    cursor: pointer;
}

.framePaymentMethods {
    color: black;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.framePaymentMethods img {
    width: 16px;
    height: 16px;
}

.framePaymentMethods span {
    padding-left: 7px;
}

.frameCategories {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: relative;
}

.errorMessage {
  color: red;
  font-size: 10px;
}

.positiveAmount {
  color: #009F10
}

.negativeAmount {
  color: #E30000
}

.custom-switch {
  margin-top: 30px;
}

/********************** TRANSITIONS ******************/

.menu-enter-active,
.menu-leave-active {
  transition: transform 0.3s;
}

.menu-enter {
  transform: translateY(-100%);
}

.menu-leave-to {
  transform: translateY(-100%);
}

.categories-display {
  animation: 0.3s fadeInUp;
}

.categories-hide {
  animation: 0.3s fadeOutUp;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10%);
  }
}

.menu-leave-to {
  transform: translateY(-100%);
}

/*********************** MEDIA QUERIES ****************/
@media screen and (min-width: 768px) {

  #header {
    justify-content: center;
    height: 80px;
  }

  #logo {
    max-height: 56px;
  }

  #logo img {
    max-height: 56px;
  }

  #pageName,
  .mobile {
    display: none;
  }

  .desktop ul {
    display: flex;
    font-size: 18px;
    height: 40px;
  }

  .menuLink {
    display: inline-block;
    width: 100%;
    font-size: 18px;
    color: #f0f0f0;
  }  

  .routerView {
    min-height: calc(100vh - 196px);
  }

  .diamondMobile {
    display: none;
  }

  .sectionTitle::before, .sectionTitle::after {
    content: "";
    flex: 1;
    border-bottom: none;
  }

  .modalContent {
    box-shadow: 2px 2px 10px rgb(0 0 0 / 25%);
    border-radius: 5px !important;
    padding: 50px;
  }

  .modalContent > div:not([class]),
  .modalContent > form {
    width: 70%;
  }
}
</style>
