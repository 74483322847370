<template>
    <div class="page">
        <div v-if="user.isOnline">
            <div v-if="atLeastOneAccount">
                <!-- Entete commune mois/ comtpes/ add/delete / solde -->
                <div class="sectionTitle">
                    <div class="monthBefore" @click="changeMonth(-1)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-left-square-fill" viewBox="0 0 16 16">
                            <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z"/>
                        </svg>
                    </div>
                    <span>{{ monthYear }}</span>
                    <div class="monthAfter" @click="changeMonth(1)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                            <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
                        </svg>
                    </div>
                </div>
                <div>
                    <div class="accounts" @click="activateAccountsList()">
                        <div class="accountName">
                            <span v-if="account">{{ account.name }}</span>
                            <span v-else>Tous les comptes</span>
                            <!-- On ne montre la selection de compte que s'il y a au moins 1 compte a selectionner -->
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-square-fill icon" viewBox="0 0 16 16" v-if="!showAccounts">
                                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5a.5.5 0 0 1 1 0z"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-square-fill icon" viewBox="0 0 16 16" v-if="showAccounts">
                                    <path d="M2 16a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2zm6.5-4.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 1 0z"/>
                                </svg>
                            </span>
                        </div>
                        <!-- Si on a un clic sur cette div, on peut la fermer -->
                        <div :class="{
                                    'categories-display': accountsDisplay,
                                    'categories-hide': accountsHide
                                }"
                            v-if="showAccounts"
                        >
                            <p @click="resetAccount()" class="accountName">Tous les comptes</p>
                            <!-- Lorsqu'on clique sur un compte, l'id est passée à la variable -->
                            <p class="accountName" v-for="account in accounts" :key="account.id" @click="selectAccount(account.id)">{{ account.name }}</p>
                        </div>
                    </div>
                </div>

                <div>
                    <center>
                        <!-- Si on a pas d'accout spécifié, on cache le button de delete account -->
                        <div class="semiColons" :style="{'justify-content': account ? null : 'center'}">
                            <button class="button smallButton" @click="addAccount()">
                                <span>Ajouter un compte</span>
                            </button>
                            <button 
                                class="button deleteButton smallButton" 
                                v-if="account"
                                @click="deleteAccount(account.name, account.id)"
                            >
                                <span>Supprimer ce compte</span>
                            </button>
                        </div>
                    </center>
                </div>
                
                <div class="field">
                    <div class="fieldBody">
                        <div class="balance">
                            <span>Solde 
                                <!-- Si on a une daté passée, le solde est connu, ce n'est pas une prévision -->
                                <span v-if="date < today">au {{ date.toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric'}) }}</span>
                                <!-- Si par contre on a une date à venir, c'est un prévisionnel -->
                                <span v-if="date > today">prévisionnel au {{ date.toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric'}) }}</span>
                            </span>
                            <!-- Ici, on gère la couleur selon l'opérateur du montant -->
                            <span :class="{
                                'positiveAmount': balance >= 0,
                                'negativeAmount': balance < 0
                            }">{{ balance.toFixed(2) }} €</span>
                        </div>
                        <!-- Si c'est le mois courant, on affiche la prévision à la fin du mois -->
                        <div class="forecast" v-if="isCurrentMonth">
                            <span>Prévision au {{ lastDayOfCurrentMonth(false) }}</span>
                            <!-- Ici, on gère la couleur selon l'opérateur du montant -->
                            <span :class="{
                                'positiveAmount': forecast >= 0,
                                'negativeAmount': forecast < 0
                            }">{{ forecast.toFixed(2) }} €</span>
                        </div>
                        <!-- On gère l'affichage du bouton de détails. Il n'apparait que si on est pas sur la page de détail, et au click, on choppe les opés -->
                        <button class="detailButton" v-if="!accountDetails" @click="getAllOperations">
                            <router-link
                                :to="{ name: 'Account' }"
                                style="text-decoration: none; color: #f0f0f0"
                            >
                                Voir le détail
                            </router-link>
                        </button>
                    </div>
                </div>

                <!-- Tous les comptes -->
                <div v-if="!accountDetails">
                    <div class="field">
                        <div class="fieldBody recap">
                            <div>
                                <span>Revenus</span>
                                <!-- un + 0 ou - 0, c'est pas beau !-->
                                <span class="positiveAmount"><span v-if="income !== 0">+</span> {{ income.toFixed(2) }} €</span>
                            </div>
                            <div>
                                <span>Dépenses</span>
                                <span class="negativeAmount"> {{ expense.toFixed(2).replace('-', '- ') }} €</span>
                            </div>
                        </div>
                    </div>
                    <center v-if="user.showStats">
                        <div class="fieldBody">
                            <div class="balance">
                                <span>Dépenses</span>
                            </div>
                            <div class="chartContainer">
                                <apexchart class="chart" type="pie" :options="options" :series="series"></apexchart>
                            </div>
                        </div>
                    </center>
                </div>

                <!-- Détails comptes -->
                <div v-else>
                    <center v-if="user.showStats">
                        <div class="fieldBody chartContainer">
                            <apexchart class="chart" type="pie" :options="options" :series="series"></apexchart>
                        </div>
                    </center>
                    <button class="button smallButton" @click="toggleHidedOperations()">
                        <span v-if="showHidedOperations">Dissimuler les opérations cachées</span>
                        <span v-else>Exposer les opérations cachées</span>
                    </button>
                    <!-- La classe field est dans le template basique, mais pour gérer la distance entre deux cadres si ils ont la même date, on ajoute la classe groupDate selon la même condition que le fieldTitle -->    
                    <div :class="{
                        'field': true,
                        'groupDate': (index > 0 && allOperations[index].date === allOperations[index-1].date)
                        }" 
                        v-for="(operation, index) in allOperationsFiltered" :key="operation.id"
                    >
                        <button 
                            type="button" 
                            :class="{
                                'btn': true, 
                                'btn-bank': true,
                                'bank-validate': showBankRecord && activeBtnBank === index,
                                'bank-unvalidate': showBankUnrecord && activeBtnBank === index,
                                }"
                            @mouseenter="toggleIconBank(index, operation.bank_recorded, true)" 
                            @mouseleave="toggleIconBank(index, operation.bank_recorded, false)"
                            @click="updateBankRecord(operation)"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-bank" viewBox="0 0 16 16" style="position: absolute; color: white">
                                <path d="M8 .95 14.61 4h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.379l.5 2A.5.5 0 0 1 15.5 17H.5a.5.5 0 0 1-.485-.621l.5-2A.5.5 0 0 1 1 14V7H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 4h.89L8 .95zM3.776 4h8.447L8 2.05 3.776 4zM2 7v7h1V7H2zm2 0v7h2.5V7H4zm3.5 0v7h1V7h-1zm2 0v7H12V7H9.5zM13 7v7h1V7h-1zm2-1V5H1v1h14zm-.39 9H1.39l-.25 1h13.72l-.25-1z"/>
                            </svg>

                            <svg width="23" height="20" viewBox="0 0 17 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="position: absolute; color: green; z-index:20" v-if="operation.bank_recorded && activeBtnBank !== index">
                                <path d="M13.4853 0.436286C13.7592 0.159748 14.1315 0.0029073 14.5208 4.00118e-05C14.9101 -0.00282727 15.2847 0.148512 15.5627 0.420985C15.8407 0.693457 15.9996 1.0649 16.0045 1.45415C16.0095 1.8434 15.8602 2.21879 15.5893 2.49829L7.74926 12.2993C7.61457 12.4443 7.45205 12.5607 7.27138 12.6415C7.09072 12.7223 6.89563 12.7659 6.69775 12.7696C6.49988 12.7733 6.30328 12.7372 6.1197 12.6632C5.93612 12.5893 5.76932 12.4791 5.62926 12.3393L0.431257 7.14329C0.155034 6.86693 -9.37257e-05 6.49217 4.24844e-08 6.10143C9.38107e-05 5.7107 0.155401 5.33601 0.431757 5.05979C0.708113 4.78356 1.08288 4.62844 1.47361 4.62853C1.86434 4.62862 2.23903 4.78393 2.51526 5.06029L6.62626 9.17229L13.4463 0.482286C13.4586 0.466474 13.472 0.451445 13.4863 0.437287L13.4853 0.436286Z"/>
                            </svg>

                            <svg width="20" height="20" viewBox="0 0 15 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="position: absolute; color: red; z-index:20" v-if="!operation.bank_recorded && activeBtnBank !== index">
                                <path d="M0.293031 1.29308C0.480558 1.10561 0.734866 1.00029 1.00003 1.00029C1.26519 1.00029 1.5195 1.10561 1.70703 1.29308L7.00003 6.58608L12.293 1.29308C12.3853 1.19757 12.4956 1.12139 12.6176 1.06898C12.7396 1.01657 12.8709 0.988985 13.0036 0.987831C13.1364 0.986677 13.2681 1.01198 13.391 1.06226C13.5139 1.11254 13.6255 1.18679 13.7194 1.28069C13.8133 1.37458 13.8876 1.48623 13.9379 1.60913C13.9881 1.73202 14.0134 1.8637 14.0123 1.99648C14.0111 2.12926 13.9835 2.26048 13.9311 2.38249C13.8787 2.50449 13.8025 2.61483 13.707 2.70708L8.41403 8.00008L13.707 13.2931C13.8892 13.4817 13.99 13.7343 13.9877 13.9965C13.9854 14.2587 13.8803 14.5095 13.6948 14.6949C13.5094 14.8803 13.2586 14.9855 12.9964 14.9878C12.7342 14.99 12.4816 14.8892 12.293 14.7071L7.00003 9.41408L1.70703 14.7071C1.51843 14.8892 1.26583 14.99 1.00363 14.9878C0.741433 14.9855 0.49062 14.8803 0.305212 14.6949C0.119804 14.5095 0.0146351 14.2587 0.0123566 13.9965C0.0100782 13.7343 0.110873 13.4817 0.293031 13.2931L5.58603 8.00008L0.293031 2.70708C0.10556 2.51955 0.000244141 2.26525 0.000244141 2.00008C0.000244141 1.73492 0.10556 1.48061 0.293031 1.29308V1.29308Z"/>
                            </svg>
                        </button>
                        <!-- On affiche la date que si elle est différente de la date de l'opé précédente (et si on a fait au moins un passage, pour eviter les erreurs de -1 dans l'array) -->
                        <div class="fieldTitle" 
                            v-if="!(index > 0 && allOperations[index].date === allOperations[index-1].date)"
                        >
                            {{ formatDateOperation(operation.date) }}
                        </div>
                        <!-- Les classes fieldBody et opération sont dans le template basique, mais pour gérer la distance entre deux cadres si ils ont la même date, on ajoute la classe groupDate selon la même condition que le fieldTitle -->    
                        <div  :class="{
                        'fieldBody': true,
                        'operation': true,
                        'groupDate': (index > 0 && allOperations[index].date === allOperations[index-1].date)
                        }"
                        @click="activateOperationComponent(operation, 'read')"
                        v-if="!operation.first_ope_of_month"
                        >
                            <div>
                                <span class="nameOperation">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16" v-if="operation.hided">
                                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                                    <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                                </svg>
                                {{ operation.name }}</span>
                                <!-- Ici, on gère la couleur selon l'opérateur du montant -->
                                <span :class="{
                                    'positiveAmount': operation.amount >= 0,
                                    'negativeAmount': operation.amount < 0
                                }">
                                    <span v-if="operation.amount >= 0">+</span>
                                    <span>{{ operation.amount }} €</span>
                                </span>
                            </div>
                            <div>
                                <!-- Ici, on gère l'affichage du nom des catégories/moyens de paiement s'il sont définis, sinon "Non défini" -->
                                <span class="typeOperation" v-if="operation.category_id">{{ operation.category.name }}</span>
                                <span class="typeOperation" v-else>Non défini</span>
                                
                                <span class="typeOperation" v-if="operation.payment_method_id">{{ operation.payment_method.name }}</span>
                                <span class="typeOperation" v-else>Non défini</span>
                            </div>
                        </div>
                    </div>
                    <div class="buttonAddOperation" @click="activateOperationComponent(null, 'create')">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div v-else>
                <center>
                    <p>Vous n'avez pas encore créé de compte, crééz en un avant de commencer !</p> 
                    <button class="button" @click="addAccount()">
                        <span>Ajouter un compte</span>
                    </button>
                </center>
            </div>

            <Account v-if="showAccountComponent"
                :modeProp = modeProp
                :id = idProp
                :nameProp = nameProp
                @close="showAccountComponent = false; getAccountsAndOperations(); resetAccount()"
            />

            <Operation v-if="showOperationComponent"
                :modeProp = modeProp
                :operationProp = operationProp
                :formatDateOperation = formatDateOperation
                :accountsProp = accountsProp
                :selectedAccountIdProp = selectedAccountIdProp
                @close="showOperationComponent = false; getAccountsAndOperations();"
            />
        </div>
        <p v-else>Vous devez être connecté pour accéder à cette partie !</p>
    </div>
</template>

<style scoped>
.sectionTitle {
    position: relative;
}

.monthBefore,
.monthAfter {
    background-color: white;
    color: #7ca8fd;
    position: absolute;
}

.monthBefore {
    left: 10%;
}

.monthAfter {
    right: 10%;
}

.field {
    height: initial;
    margin-top: 40px;
}

.groupDate {
    margin-top: 5px;
}

.button {
    margin-top: 36px;
    width: 90%;
}

.semiColons {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.semiColons > .button {
  width: 45%;
}

.detailButton {
    height: 20px;
    margin: 15px 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.balance,
.forecast,
.recap > div,
.operation,
.operation > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.balance {
    font-size: 18px;
}

.forecast {
    font-size: 15px;
    margin-left: 10px;
}

.recap {
    padding-top: 20px;
    padding-bottom: 20px;
}

.operation {
    padding-top: 5px;
    padding-bottom: 10px;
}

.operation > div {
    width: 100%;
}

.nameOperation {
    color: #696969
}

.typeOperation {
    font-size: 12px
}

.btn-bank {
    display: none;
}

.buttonAddOperation {
    position: fixed;
    right: 30px;
    bottom: 110px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    color: #7CA8FD;
}

.buttonAddOperation svg{
    width: 100%;
    height: 100%;
}

.chart {
    width: 100%;
}

.chartContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 768px) {
    .monthBefore {
        left: 30%;
    }

    .monthAfter {
        right: 30%;
    }

    .btn-bank {
        position: absolute;
        bottom: 20%;
        left: 20px;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #7CA8FD;
    }

    .bank-validate {
        background-color: green;
    }

    .bank-unvalidate {
        background-color: red;
    }

    .chart {
        width: 400px;
    }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import Account from '../components/AccountComponent.vue'
import Operation from '../components/OperationComponent.vue'

export default {
    data () {
        return {
            accounts: null,
            // date est la date qu'on manipule
            date: new Date(),
            // today est la date du jour, appelée à ne jamais changer
            today: new Date(),
            monthYear: null,
            balance: 0,
            isCurrentMonth: true,
            forecast: 0,
            income: 0,
            expense: 0,
            showAccounts: false,
            idAccountSelected: null,
            account: null,
            allOperations: [],
            // COMPONENT ACCOUNT
            showAccountComponent: false,
            modeProp: null,
            nameProp: null,
            idProp: null,
            // COMPONENT OPERATION
            operationProp: null,
            showOperationComponent: false,
            accountsProp: null,
            selectedAccountIdProp: null,
            atLeastOneAccount: true,
            showHidedOperations: 0,
            accountsDisplay: null,
            accountsHide: null,
            showBankUnrecord: false,
            showBankRecord: false,
            activeBtnBank: null,
            options: {
                labels: [],
            },
            series: []
        }
    },
    props: [
        'accountDetails',
    ],
    components: {
        Account,
        Operation
    },
    computed: {
        ...mapGetters({
            // On importe les données utilisateur
            user: "getUser",
        }),
        allOperationsFiltered: function() {
            return this.allOperations.filter(p => p.first_ope_of_month !== 1)
        },
    },
    methods: {
        ...mapActions({
            // On importe le get des comptes
            getAccounts: "accounts",
            bankRecord: "bankRecord",
            categories: "categories"
        }),
        countOperationsType() {
            let allOperations = []
            // On fill l'array avec toutes les opés, tous comptes confondus
            if (this.account) {
                for (let operation of this.account.operations) {
                    allOperations.push(operation)
                }
            } else {
                for (let account of this.accounts) {
                    for (let operation of account.operations) {
                        allOperations.push(operation)
                    }
                }
            }
            this.categories()
                .then((response) => {
                    let categories = response.data
                    // On reinit les labels et les series
                    let categoryLabels = []
                    this.series = []
                    // Et donc pour chaque catégorie, on va fill avec le nombre d'opérations qui correspondent à cette catégorie
                    for (let category of categories){
                        let amountOfThisCategory = 0;
                        for (let operation of allOperations) {
                            if (operation.category_id === category.id) {
                                // C'est un graphique de dépenses, on prend donc les opérations négatives
                                if (operation.amount < 0) {
                                    amountOfThisCategory += operation.amount
                                }
                            }
                        }
                        categoryLabels.push(category.name)
                        // Le graphique ne prend que des positifs
                        amountOfThisCategory = amountOfThisCategory.toFixed(2)
                        this.series.push(amountOfThisCategory * -1);
                        this.options = {
                            labels: categoryLabels
                        }
                    }
                })
        },
        updateBankRecord(operation) {
            console.log('on a cliqué')
            this.bankRecord({
                id: operation.id
            })
                .then(() => {
                    operation.bank_recorded = !operation.bank_recorded
                })
                .catch(error => {
                    this.$toast.warning(error)
                })
        },
        toggleIconBank(operationIndex, bankRecorded, isHover = false) {
            // Quand on passe sur l'icone
            if(isHover) {
                // On selectionne le bouton a activer
                this.activeBtnBank = operationIndex
                // On check l'état de l'opé, si elle est enregistrée par la banque, on monte l'option pour effacer cet enregistrement banque
                if(bankRecorded) {
                    this.showBankUnrecord = true
                } else {
                // SInon, on doit afficher l'option pour l'enregistrer en banque
                    this.showBankRecord = true
                }
            } else {
                // Quand on quitte l'icone, on cache toutes les options
                this.showBankUnrecord = false
                this.showBankRecord = false
                this.activeBtnBank = null
            }
        },
        triggerAccountsAnimation() {
            if (this.accountsDisplay === null) {
                this.accountsDisplay = true;
                this.accountsHide = false;
            } else {
                this.accountsDisplay = !this.accountsDisplay
                this.accountsHide = !this.accountsHide
            }
        },
        activateAccountsList() {
            // On trigger les animations
            this.triggerAccountsAnimation()
            // Pour que la liste ne soit pas cachée immédiatement a la fermeture de la liste, on met un timeout seulement si on demande la fermeture
            if (this.accountsHide) {
                setTimeout(()=>{
                    this.showAccounts = !this.showAccounts
                }, 300);
            } else {
                this.showAccounts = !this.showAccounts
            }
        },
        toggleHidedOperations() {
            this.showHidedOperations === 1 ? this.showHidedOperations = 0 : this.showHidedOperations = 1;
            // On refresh les comptes et les opérations
            this.getAccountsAndOperations()
        },
        checkNumberOfAccounts() {
            if (this.accounts) {
                if (this.accounts.length > 0) {
                    this.atLeastOneAccount = true
                } else {
                    this.atLeastOneAccount = false
                }
            }
        },
        lastDayOfCurrentMonth(onlyDay) {
            // On choppe le dernier jour du mois
            let lastDayOfThisMonth = Object.assign(new Date(), this.today)
            lastDayOfThisMonth.setDate(15)
            lastDayOfThisMonth.setMonth(lastDayOfThisMonth.getMonth() + 1)
            lastDayOfThisMonth.setDate(0)

            // Si on veut seulement le jour
            if (onlyDay) {
                return lastDayOfThisMonth.getDate()
            }

            // On formatte le mois
            // On récupère d'abord la date en str (language par défaut, version longue du mois, et l'année en numérique)
            let month = lastDayOfThisMonth.toLocaleString('default', { month: 'long'})
            // Puis on met la majuscule qui va bien. On met d'abord juste la première lettre en maj, puis on ajoute le reste du mot (sans la première lettre donc)
            month = month[0].toUpperCase() + month.substring(1)

            // Et on retourne le tout formaté DD MM
            return lastDayOfThisMonth.getDate() + ' ' + month
        },
        checkIfIsCurrentMonth() {
            if (this.today.toLocaleString('default', { month: 'long', year: 'numeric'}) === this.date.toLocaleString('default', { month: 'long', year: 'numeric'})) {
                this.isCurrentMonth = true
            } else {
                this.isCurrentMonth = false
            }
        },
        changeMonth(action) {
            let todayDay = this.today.getDate()

            // On traite la date demandée
            // Si on demande le mois suivant, on ajoute 2 mois, pour revenir a +1 mois avec le setdate
            if (action > 0) {
                // On a un 15 dans chaque mois, on se base donc sur ce 15 pour changer les mois (pas avoir de bug 30/31 et d'ajout de 3 mois)
                this.date.setDate(15)
                this.date.setMonth(this.date.getMonth() + 2)
            }
            // Si on demande le mois précédent, c'est fait direct avec le setDate
            this.date.setDate(0)

            // On check maintenant si le mois demandé est le mois courant
            this.checkIfIsCurrentMonth()
            //Si la date demandée est le mois courant, on met le jour à jour LOL
            if (this.isCurrentMonth) {
                this.date.setDate(todayDay)
            }

            // On check si le mois demandé est le mois en cours
            this.checkIfIsCurrentMonth()

            // On formatte la date
            this.formatDate()

            // On récupère les comptes et les opérations
            this.getAccountsAndOperations()
        },
        getAccountsAndOperations() {
            // On récupère les comptes liés à l'utilisateur (l'identification de l'utilisateur se fait côté back), avec les opérations du mois selectionné
            this.getAccounts({
                //On veux les mois en partant de 1, pas de 0
                month: this.date.toLocaleString('default', { month: '2-digit'}),
                year: this.date.getFullYear(),
                hided: this.showHidedOperations
            })
                .then((response) => {
                    this.accounts = response.data

                    //On check le nombre de comtpes, si on doit cacher la page ou pas
                    this.checkNumberOfAccounts()

                    // Si on a déjà un compte selectionné par l'utilisateur
                    if(this.account) {
                        // On lui attribue les nouvelles données
                        this.selectAccount(this.account.id)
                    }
                    // Si on est sur la page de détail, on setup les opérations
                    if (this.accountDetails) {
                        this.getAllOperations() 
                    }
                    //On calcule le solde
                    this.calculateBalance()
                    this.calculateForecast()
                    this.countOperationsType()
                }).catch(error => {
                    this.$toast.warning(error)
                })
        },
        selectAccount(id) {
            // On récupère le compte selectionné (l'identification de l'utilisateur se fait côté back), avec les opérations du mois selectionné
            for (let account of this.accounts) {
                if (id === account.id) {
                    this.account = account
                    // Si on est sur la page de détail, on setup les opérations
                    if (this.accountDetails) {
                        this.getAllOperations() 
                    }
                    this.countOperationsType()
                    break;
                }
            }
            //On calcule le solde
            this.calculateBalance()
            this.calculateForecast()
        },
        resetAccount() {
            this.account = null
            //On calcule le solde
            this.calculateBalance()
            this.calculateForecast()
            // On recalcule les opés
            this.getAllOperations()
            this.countOperationsType()
        },
        formatDate() {
            // On formatte le mois
            // On récupère d'abord la date en str (language par défaut, version longue du mois, et l'année en numérique)
            this.monthYear = this.date.toLocaleString('default', { month: 'long', year: 'numeric'})
            // Puis on met la majuscule qui va bien. On met d'abord juste la première lettre en maj, puis on ajoute le reste du mot (sans la première lettre donc)
            this.monthYear = this.monthYear[0].toUpperCase() + this.monthYear.substring(1)
        },
        calculateBalance() {
            // On reset le solde, le crédit et le débit
            this.balance = 0
            this.income = 0,
            this.expense = 0

            // Si on a selectionné un compte en particulier
            if (this.account) {
                // on choppe les opérations rattachées
                for (let operation of this.account.operations) {

                    // On convertit la date des opérations en date lisible
                    let operationDate = new Date(operation.date)
                    // Si la date des opérations est inférieure à la date demandée, on calcule le solde a la date demandée
                    if (operationDate < this.date) {
                        this.balance += operation.amount
                    }

                    // Si ce n'est pas la première opé du mois, on l'ajoute aux calculs
                    if(!operation.first_ope_of_month) {
                        // Si le montant est positif, on l'ajoute au crédit, sinon au débit
                        if(operation.amount > 0) {
                            this.income += operation.amount
                        } else {
                            this.expense += operation.amount
                        }
                    }
                }          
            } else {
                // Si on veut tous les comptes
                for (let account of this.accounts) {
                    // puis les opérations rattachées
                    for (let operation of account.operations) {

                        // On convertit la date des opérations en date lisible
                        let operationDate = new Date(operation.date)
                        // Si la date des opérations est inférieure à la date demandée, on calcule le solde a la date demandée
                        if (operationDate < this.date) {
                            this.balance += operation.amount
                        }

                        // Si ce n'est pas la première opé du mois, on l'ajoute aux calculs
                        if(!operation.first_ope_of_month) {
                            // Si le montant est positif, on l'ajoute au crédit, sinon au débit
                            if(operation.amount > 0) {
                                this.income += operation.amount
                            } else {
                                this.expense += operation.amount
                            }
                        }
                    }
                }
            }
        },
        calculateForecast() {
            // On check si le mois demandé est le mois en cours
            this.checkIfIsCurrentMonth()

            if(this.isCurrentMonth) {
                // On reset la prévision
                this.forecast = 0
                
                // Si on a selectionné un compte en particulier
                if (this.account) {
                    // on choppe les opérations rattachées
                    for (let operation of this.account.operations) {
                        this.forecast += operation.amount
                    }                    
                } else {
                    // On choppe les comptes
                    for (let account of this.accounts) {
                        // puis les opérations rattachées
                        for (let operation of account.operations) {
                            this.forecast += operation.amount
                        }
                    }
                }
            }
        },
        getAllOperations() {
            // On reset le tableau des opés
            this.allOperations = []
            // SI on a un compte selectionné
            if (this.account) {
                // On parcours les opés du compte en question poiur les mettre dans l'array
                for (let operation of this.account.operations) {
                    this.allOperations.push(operation)
                }   
            // Si on a plusieurs comptes           
            } else {
                // On choppe les comptes
                for (let account of this.accounts) {
                    // Puis les opés
                    for (let operation of account.operations) {
                        // Pour les mettre dans l'array
                        this.allOperations.push(operation)  
                    }
                }
            }
            // On trie le tableau par date
            this.sortOperationsByDate()
        },
        sortOperationsByDate() {
            this.allOperations.sort( ( a, b) => {
                // Si la date de l'opé A est supérieur à la date de l'opé B, on la place en premier (on veut un ordre décroissant)
                if (a.date > b.date) {
                    return -1
                }
                // A l'inverse, Si la date de l'opé A est inférieure à la date de l'opé B, on la place en dernier
                if (a.date < b.date) {
                    return 1
                }
            });
        },
        formatDateOperation(date, withReference = true) {
            let dateUnformatted = new Date(date)
            
            // On check si l'année et le mois correspodnent à ceux d'ajd, et qu'on veuille bien formatter le tout
            if (dateUnformatted.getMonth() === this.today.getMonth() && dateUnformatted.getYear() === this.today.getYear() && withReference) {
                // Si c'est le jour précédent
                if (dateUnformatted.getDate() === this.today.getDate() - 1) {
                    return 'Hier'
                // Si c'est le jour j
                } else if (dateUnformatted.getDate() === this.today.getDate()) {
                    return 'Aujourd\'hui'
                // Si c'est le jour suivant
                } else if (dateUnformatted.getDate() === this.today.getDate() + 1) {
                    return 'Demain'
                } else {
                    return dateUnformatted.toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric'})
                }
            // Sinon on formatte DD MM YYYY
            } else {
                return dateUnformatted.toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric'})
            }
        },
        addAccount() {
            this.showAccountComponent = true
            this.modeProp = 'create'
            this.idProp = null
            this.nameProp = null
        },
        deleteAccount(name, id) {
            this.showAccountComponent = true
            this.modeProp = 'delete'
            this.idProp = id
            this.nameProp = name
        },
        activateOperationComponent(operation, mode) {
            this.showOperationComponent = true
            this.modeProp = mode
            this.operationProp = operation
            this.accountsProp = this.accounts

            // Si on a un compte spécifié en particulier, on le passe lui (pour renseigner direct le compte de l'opé, puisqu'on veut créer une opération sur ce compte)
            if (this.account) {
                this.selectedAccountIdProp = this.account.id
            }
        }
    },
    mounted() {
        // On formatte la date
        this.formatDate()

        // On récupère les comptes et les opérations
        this.getAccountsAndOperations()
        console.log(this.user)
    }
};
</script>