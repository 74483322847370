<template>
    <div class="modalContainer">
        <div class="modalContent">
            <div class="modalCloseCross" @click="$emit('close')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="currentColor"
                  class="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
            </div>

            <!-- EDIT - CREATE -->
            <form @submit.prevent="submitForm" method="post" v-if="mode === 'create' || mode === 'edit'">
                <div class="recurrenceDateInput">
                    Date de début
                    <input type="date" v-model="startDate" :min="minDateProp"/>
                </div>
                <span class="errorMessage" v-if="startDateError">Vous devez définir une date de début.</span>
                <div class="recurrenceDateInput">
                    Date de fin
                    <input type="date" v-model="endDate" :min="startDate"/>
                </div>
                <span class="errorMessage" v-if="endDateError">Vous devez définir une date de fin.</span>

                <div class="recurrenceFieldContainer">
                    <div class="recurrenceField" @click="showRecurrenceList = !showRecurrenceList">
                        <span v-if="selectedRecurrence" :style="{'font-size': nameSelectedRecurrence.length > 11 ? '14px' : 'inherit'}">{{ nameSelectedRecurrence }}</span>
                        <span v-else>Récurrence</span>
                        <div class="modalContainer" v-if="showRecurrenceList">
                            <div class="modalContent">
                                <div class="recurrencesList">
                                    <div class="recurrenceItem" v-for="recurrence in recurrences" :key="recurrence.id" @click="selectedRecurrence = recurrence.id" :style="{'font-size': recurrence.name.length > 11 ? '14px' : 'inherit'}">
                                        {{ recurrence.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span class="errorMessage" v-if="recurrenceError">Vous devez selectionner une récurrence.</span>
                    </div>
                </div>

                <div class="modalButtons">
                    <button  class="validationButton"> 
                        <input type="submit" value="Valider"/>
                    </button>
                    <button class="modalCancelButton" @click="$emit('close')">Annuler</button>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    props: [
        'modePropRecurrence',
        'minDateProp'
    ],
    data () {
        return {
            mode: this.modePropRecurrence,
            startDate: null,
            endDate: null,
            recurrenceField: null,
            recurrences: null, 
            showRecurrenceList: false,
            selectedRecurrence: false,
            startDateError: false,
            endDateError: false,
            recurrenceError: false
        }
    },
    computed: {
        ...mapGetters({
            // On importe les données utilisateur
            user: "getUser",
        }),
        nameSelectedRecurrence: function () {
            for (let recurrence of this.recurrences) {
                if(recurrence.id == this.selectedRecurrence) {
                    return recurrence.name
                }
            }
            return 'N/A'
        }
    },
    methods: {
        ...mapActions({
            getRecurrencesNames: "recurrences_names",
            storeRecurrence: "createRecurrence"
        }),
        submitForm() {
            /*********** VALIDATION DES DONNEES ************/
            this.startDate === null || this.startDate === '' ? this.startDateError = true : this.startDateError = false
            this.endDate === null || this.endDate === '' ? this.endDateError = true : this.endDateError = false
            this.selectedRecurrence === null || this.selectedRecurrence === '' ? this.recurrenceError = true : this.recurrenceError = false

            // On traite les données entrées 
            if (!this.startDateError && !this.endDateError && !this.recurrenceError) {
                if (this.mode === 'create') {
                    // On fait appel l'action store
                    this.storeRecurrence({
                        start_date: this.startDate,
                        end_date: this.endDate,
                        recurrence_name: this.selectedRecurrence
                        // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                    }).then((data) => {
                        let toast = (
                            <div>
                                <span>Le récurrence a bien été créée.</span>
                            </div>
                        )
                        this.$toast.success(toast)
                        this.$emit('recurrenceAdded', data.data.insertedRecurrenceID, this.startDate)
                        // Et ici l'erreur, envoyée par reject
                    }).catch(error => {
                        this.$toast.warning(error)
                    })
                }
            }
        },
    },
    mounted() {  
        // On choppe la liste des recurrences
        this.getRecurrencesNames()
            .then((response) => {
                this.recurrences = response.data
                // Et ici l'erreur, envoyée par reject
            }).catch(error => {
                this.$toast.warning(error)
            })
    }
}
</script>

<style scoped>

.recurrenceDateInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    position: relative;
}

.recurrenceDateInput > input {
    width: 60%;
}

.recurrenceFieldContainer,
.recurrenceField,
.recurrencesList {
    display: flex;
    justify-content: center;
    align-items: center;
}

.recurrenceField {
    width: 100px;
    height: 25px;
    color: white;
    background-color: #696969;
    border-radius: 2px;
}

.recurrencesList {
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 20px;
    background-color: white;
}

.recurrencesList > div {
    width: 100px;
    height: 25px;
    color: #696969;
    border: 1px solid #696969;
    border-radius: 2px;
}

.recurrenceItem {
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>
