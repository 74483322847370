import Vue from 'vue'
import VueRouter from 'vue-router'
import Connection from '../views/Connection.vue'
import Register from '../views/Register.vue'
import WebsiteConfig from '../views/WebsiteConfig.vue'
import Accounts from '../views/Accounts.vue'
import DebtUsers from '../views/DebtUsers.vue'
import Debts from '../views/Debts.vue'
import User from '../views/User.vue'
import ForgotPassword from '../views/ForgotPassword.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Connection
  },
  {
    path: '/connexion',
    name: 'Connection',
    component: Connection
  },
  {
    path: '/inscription',
    name: 'Register',
    component: Register
  },
  {
    path: '/reinistialisationpass/:token',
    name: 'ForgotPasswordToken',
    component: ForgotPassword
  },
  {
    path: '/reinistialisationpass',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/configuration',
    name: 'WebsiteConfig',
    component: WebsiteConfig
  },
  {
    path: '/comptes',
    name: 'Accounts',
    component: Accounts,
  },
  {
    path: '/compte',
    name: 'Account',
    component: Accounts,
    props: {
      accountDetails: true
    }
  },
  {
    path: '/utilisateurs',
    name: 'DebtUsers',
    component: DebtUsers,
  },
  {
    path: '/dettes',
    name: 'Debts',
    component: Debts,
  },
  {
    path: '/moncompte',
    name: 'User',
    component: User,
  },
]

const router = new VueRouter({
  routes
})

export default router
