<template>
    <div class="modalContainer">
        <div v-if="atLeastOneDebtUser" class="modalContent">
            <div class="modalCloseCross" @click="$emit('close')">
                <span class="badge badge-success" v-if="debt && debt.refunded === 1">Remboursée</span>
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                class="bi bi-x"
                viewBox="0 0 16 16"
                >
                <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
                </svg>
            </div>

            <!-- READ -->
            <!-- Ici, on a forcément un debt -->
            <div v-if="mode === 'read'" style="width: 100%">
                <div class="debtType">
                    <button type="button" class="btnDebt btn btn-primary">{{ typeDebt }}</button>
                </div>

                <span>{{ typeDebt }} à {{ debt.debt_user.name }}</span><span>, {{ debt.amount }} €</span>

                <button class="validationButton refundButton" @click="showRefund = true" v-if="debtRefunded === 0">Rembourser</button>
                
                <div class="field">
                    <div class="fieldTitle">
                        <span>Commentaire</span>
                    </div>
                    <div class="fieldBody">
                        {{ debt.comment }}
                    </div>
                </div>

                <div class="modalButtons">
                    <button class="modalCancelButton" @click="$emit('close')">Annuler</button>
                    <button @click.prevent="mode = 'edit'" v-if="debtRefunded !== 1">Modifier</button>
                    <button class="deleteButton" @click="mode = 'delete'">Supprimer</button>
                </div>
            </div>

            <!-- EDIT - CREATE -->
            <form @submit.prevent="submitForm" method="post" v-if="mode === 'edit' || mode === 'create'">
                <div class="debtType">
                    <button type="button" :class="{
                        'btnDebt': true,
                        'btn': true,
                        'btn-light': type == 'to',
                        'btn-primary': type == 'by'
                    }"
                    @click="type='by'">Prêt</button>
                    <button type="button" :class="{
                        'btnDebt': true,
                        'btn': true,
                        'btn-light': type == 'by',
                        'btn-primary': type == 'to'
                    }"
                    @click="type='to'">Emprunt</button>
                </div>

                <div class="field">
                    <div class="fieldTitle">
                        <span>Utilisateur</span>
                    </div>
                    <div class="fieldBody">
                        <select v-model="debtUserID">
                            <option v-for="debtUser in debtUsers" 
                            :value="debtUser.id" :key="debtUser.id"
                            >
                                {{ debtUser.name }}
                            </option>
                        </select>
                    </div>
                    <span class="errorMessage" v-if="userError">Vous devez choisir un utilisateur.</span>
                </div>

                <div class="field">
                    <div class="fieldTitle">
                        <span>Montant</span>
                    </div>
                    <div class="fieldBody">
                        <input type="text" placeholder="Ajouter un commentaire" v-model="debtAmount"/>
                    </div>
                    <span class="errorMessage" v-if="amountError">Vous devez définir un montant.</span>
                </div>
                
                <div class="field">
                    <div class="fieldTitle">
                        <span>Commentaire</span>
                    </div>
                    <div class="fieldBody">
                        <textarea placehodler="commentaire" v-model="debtComment"/>
                    </div>
                </div>

                <div class="modalButtons">
                    <button  class="validationButton"> 
                        <input type="submit" value="Valider"/>
                    </button>
                    <button class="deleteButton" @click="mode = 'delete'" v-if="mode === 'edit'">Supprimer</button>
                    <button class="modalCancelButton" @click="$emit('close')">Annuler</button>
                </div>
            </form>
            
            <!-- DELETE -->
            <div class="contentShow" v-if="mode === 'delete'">
                <p>Souhaitez-vous vraiment supprimer cette dette ?</p>

                <div class="modalButtons">
                    <button class="modalCancelButton" @click="$emit('close')">Annuler</button>
                    <button class="deleteButton" @click="submitForm">Supprimer</button>
                </div>
            </div>

            <!-- Fenetre de confirmation de remboursement -->
            <div class="modalContainer" v-if="showRefund" style="z-index: 99999999999">
                <div class="modalContent">
                    <p>Souhaitez-vous vraiment noter cette dette comme remboursée ?</p>

                    <div class="modalButtons">
                        <button class="modalCancelButton" @click="showRefund = false">Annuler</button>
                        <button class="validationButton" @click="markAsRefunded">Valider</button>
                    </div>
                </div>
            </div>
        </div> 
        <div v-else class="modalContent">
            <DebtUser
                v-if="showDebtUserModal"
                modeProp = 'create'
                @close="showDebtUserModal = false; refreshDebtUsers()"
            />
            <div class="modalContent">
                <div class="modalCloseCross" @click="$emit('close')">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="currentColor"
                    class="bi bi-x"
                    viewBox="0 0 16 16"
                    >
                    <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                    />
                    </svg>
                </div>
                <center>
                    <p>Vous n'avez pas encore créé d'utilisateur, crééz en un avant de commencer !</p> 
                    <button class="button" @click="addDebtUser()">
                        <span>Ajouter un utilisateur</span>
                    </button>
                </center>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DebtUser from '../components/DebtUserComponent.vue'

export default {
    props: [
        'modePropDebt',
        'debtProp',
    ],
    components: {
        DebtUser
    },
    data () {
        return {
            // On défini des variables qui prenent la valeur des props, puisqu'elles seront sujettes à modifications
            mode: this.modePropDebt,
            debt: this.debtProp,
            debtUserID: null,
            debtAmount: 0,
            debtComment: null,
            type: 'by',
            debtUsers: null,
            debtID: null,
            debtRefunded: false,
            showRefund: false,
            userError: false,
            amountError: false,
            atLeastOneDebtUser: true,
            showDebtUserModal: false
        }
    },
    computed: {
        ...mapGetters({
            // On importe les données utilisateur
            user: "getUser",
        }),
        typeDebt: function() {
            if (this.debt.type === "by") {
                return "Prêt"
            } else {
                return "Emprunt"
            }
        },
    },
    methods: {
        ...mapActions({
            getDebtUsers: "debtUsers",
            updateDebt: "updateDebt",
            storeDebt: "createDebt",
            deleteDebt: "deleteDebt"
        }),
        checkNumberOfDebtUsers() {
            if (this.debtUsers.length > 0) {
                this.atLeastOneDebtUser = true
            } else {
                this.atLeastOneDebtUser = false
            }
        },
        addDebtUser() {
            this.showDebtUserModal = true
        },
        markAsRefunded() {
            this.showRefund = false
            // Si on marque l'état de remboursement, on update de suite
            if (this.debtUserID && this.debtAmount) {
                // On fait appel l'action update
                this.updateDebt({
                    id: this.debtID,
                    amount: this.debtAmount,
                    type: this.type,
                    debt_user_id: this.debtUserID,
                    comment: this.debtComment,
                    refunded: true
                    // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                }).then(() => {
                    let toast = (
                        <div>
                            <span>La dette a bien été remboursée.</span>
                        </div>
                    )
                    this.$toast.success(toast)
                    // Les paramètre sont Deleted et l'id de la dette
                    this.$emit('debtChanged', false, this.debtID)
                    // Et ici l'erreur, envoyée par reject
                }).catch(error => {
                    this.$toast.warning(error)
                })
            }
        },
        submitForm() {
            if(this.mode === 'delete') {
                // On fait appel l'action delete
                this.deleteDebt({
                    id: this.debtID
                    // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                }).then(() => {
                    let toast = (
                        <div>
                            <span>La dette a bien été supprimée.</span>
                        </div>
                    )
                    this.$toast.success(toast)
                    // Les paramètre sont Deleted et l'id de la dette
                    this.$emit('debtChanged', true, this.debtID)
                    // Et ici l'erreur, envoyée par reject
                }).catch(error => {
                    this.$toast.warning(error)
                })
            }
            /*********** VALIDATION DES DONNEES ************/
            this.debtUserID === null || this.debtUserID === '' ? this.userError = true : this.userError = false
            this.amount === null || this.amount === '' ? this.amountError = true : this.amountError = false

            // On traite les données entrées 
            if (!this.userError && !this.amountError) {
                if(this.mode === 'edit') {
                    // On fait appel l'action update
                    this.updateDebt({
                        id: this.debtID,
                        amount: this.debtAmount,
                        type: this.type,
                        debt_user_id: this.debtUserID,
                        comment: this.debtComment,
                        refunded: false
                        // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                    }).then(() => {
                        let toast = (
                            <div>
                                <span>La dette a bien été mise à jour.</span>
                            </div>
                        )
                        this.$toast.success(toast)
                        // Les paramètre sont Deleted et l'id de la dette
                        this.$emit('debtChanged', false, this.debtID)
                        // Et ici l'erreur, envoyée par reject
                    }).catch(error => {
                        this.$toast.warning(error)
                    })
                } else if (this.mode === 'create') {
                    // On fait appel l'action store
                    this.storeDebt({
                        amount: this.debtAmount,
                        type: this.type,
                        debt_user_id: this.debtUserID,
                        comment: this.debtComment,
                        refunded: false
                        // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                    }).then((data) => {
                        let toast = (
                            <div>
                                <span>La dette a bien été créée.</span>
                            </div>
                        )
                        this.$toast.success(toast)
                        // Les paramètre sont Deleted et l'id de la dette
                        this.$emit('debtChanged', false, data.data.insertedDebtID)
                        // Et ici l'erreur, envoyée par reject
                    }).catch(error => {
                        this.$toast.warning(error)
                    })
                }
            }
        },
        refreshDebtUsers() {
            this.getDebtUsers()
                .then(response => {
                    this.debtUsers = response.data
                    this.checkNumberOfDebtUsers()
                })
                .catch(error => {
                    this.$toast.warning(error)
                })
        },
    },
    mounted() {
        // On récupère les debt users
        this.getDebtUsers()
            .then((response) => {
                this.debtUsers = response.data
                this.checkNumberOfDebtUsers()
            }).catch(error => {
                this.$toast.warning(error)
            })

        if(this.debt) {
            this.debtID = this.debt.id
            this.debtUserID = this.debt.debt_user.id
            this.debtAmount = this.debt.amount
            this.debtComment = this.debt.comment
            this.type = this.debt.type
            this.debtRefunded = this.debt.refunded
        }
        console.log(this.debt)
    }
}
</script>

<style scoped>
select {
    width: 100%;
    border-width: 0;
}

.field {
    margin-top: 45px;
}

.fieldBodyCategory {
    color: white;
}

.fieldBodyCategory > * {
    padding-left: 10px;
}

.debtType {
    width: 100%;
    margin-bottom: 35px;
    display: flex;
    justify-content: space-around;
}

.btnDebt {
    width: 100px;
}

.refundButton {
    width: 100%;
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalCloseCross {
    align-items: center;
}
</style>
