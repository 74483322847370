<template>
    <div class="modalContainer">
        <div class="modalContent">
            <div class="modalCloseCross" @click="$emit('close')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="currentColor"
                  class="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
            </div>

            <!-- EDIT - CREATE -->
            <form @submit.prevent="submitForm" method="post" v-if="modeProp === 'create'">
                <p>Pour ajouter un compte, indiquez un nom, et le montant initial.</p>
                <div class="field">
                    <div class="fieldTitle">
                        <span>Nom</span>
                    </div>
                    <div class="fieldBody">
                        <input type="text" placeholder="Nom du compte" v-model="name"/>
                    </div>
                    <span class="errorMessage" v-if="nameError">Le nom du compte est obligatoire.</span>
                </div>

                <div class="field">
                    <div class="fieldTitle">
                        <span>Montant initial</span>
                    </div>
                    <div class="fieldBody fieldBodyCategory">
                        <input type="number" step="0.01" placeholder="Montant initial du compte" v-model="amount"/>
                    </div>
                    <span class="errorMessage" v-if="amountError">Le montant initial est obligatoire.</span>
                </div>

                <div class="modalButtons">
                    <button  class="validationButton"> 
                        <input type="submit" value="Valider"/>
                    </button>
                    <button class="modalCancelButton" @click="$emit('close')">Annuler</button>
                </div>
            </form>
            
            <!-- DELETE -->
            <div class="contentShow" v-if="modeProp === 'delete'">
                <p>
                    <b>Toutes les opérations qui sont enregistrées sur ce compte seront irrémédiablement effacées.</b><br/>
                    Validez la suppression du compte <b>"{{ nameProp }}"</b> en entrant les mots "{{ confirmationTemplate }}".
                </p>

                <div class="field">
                    <div class="fieldTitle">
                        <span>Confirmation</span>
                    </div>
                    <div class="fieldBody">
                        <input type="text" :placeholder="confirmationTemplate" v-model="confirmation"/>
                    </div>
                    <span class="errorMessage" v-if="confirmationError">Veuillez confirmer la suppression en entrant les mots "{{ confirmationTemplate }}"</span>
                </div>

                <div class="modalButtons">
                    <button class="modalCancelButton" @click="$emit('close')">Annuler</button>
                    <button class="deleteButton" @click="submitForm">Supprimer</button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    props: [
        'modeProp',
        'id',
        'nameProp'
    ],
    data () {
        return {
            name: null,
            amount: null,
            confirmation: null,
            confirmationTemplate: 'Je confirme',
            confirmationError: false,
            nameError: false,
            amountError: false
        }
    },
    computed: {
        ...mapGetters({
            // On importe les données utilisateur
            user: "getUser",
        }),
    },
    methods: {
        ...mapActions({
            createAccount: "createAccount",
            deleteAccount: "deleteAccount",
        }),
        submitForm() {
            if(this.modeProp === 'delete') {
                // Si on a bien entré la confirmation
                if (this.confirmation === this.confirmationTemplate) {
                    // On fait appel l'action delete
                    this.deleteAccount({
                        id: this.id
                        // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                    }).then(() => {
                        let toast = (
                            <div>
                                <span>Le compte a bien été supprimé</span>
                            </div>
                        )
                        this.$toast.success(toast)
                        this.$emit('close')
                        // Et ici l'erreur, envoyée par reject
                    }).catch(error => {
                        this.$toast.warning(error)
                    })
                } else {
                    this.confirmationError = true
                }
            }

            /********** VALIDATION DES DONNEES ************/
            this.name === null || this.name === '' ? this.nameError = true : this.nameError = false
            this.amount === null || this.amount === '' ? this.amountError = true : this.amountError = false

            // On traite les données entrées 
            if (!this.nameError && !this.amountError) {
                if (this.modeProp === 'create') {
                    // On fait appel l'action store
                    this.createAccount({
                        name: this.name,
                        initial_amount: this.amount,
                        // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                    }).then(() => {
                        let toast = (
                            <div>
                                <span>Le compte a bien été créé</span>
                            </div>
                        )
                        this.$toast.success(toast)
                        this.$emit('close')
                        this.$emit('close')
                        // Et ici l'erreur, envoyée par reject
                    }).catch(error => {
                        this.$toast.warning(error)
                    })
                }
            }
        }
    },
}
</script>

<style scoped>
.field {
    margin-top: 45px;
}

/* Tous les .field qui sont après des .field */
.field ~ .field {
    margin-top: 45px;
}

.fieldBodyCategory {
    color: white;
}

.fieldBodyCategory > * {
    padding-left: 10px;
}

.modalCloseCross {
    width: 100%
}
</style>
