<template>
    <div class="page">
        <div class="sectionTitle">
            <span>Catégories</span>
        </div>

        <!-- Si l'utilisateur n'est pas un admin -->
        <div v-if="!user.isAdmin">
            <p>Vous n'avez pas l'autorisation de consulter cette page</p>
        </div>

        <div v-else>
            <div class="field">
                <div class="fieldTitle">
                    <span>Moyens de paiement</span>
                </div>
                <div class="fieldBody">
                    <!-- On boucle les méthodes de paiement, en ajoutant la possibilité, en cliquant, de voir le détail de la méthode de paiement -->
                    <div class="frameOptions framePaymentMethods" 
                        v-for="paymentMethod in paymentMethods" 
                        :key="paymentMethod.id" 
                        @click.prevent="showPaymentMethod(paymentMethod.id, paymentMethod.name, paymentMethod.logo, paymentMethod.default)"
                    >
                        <img :src="paymentMethod.logo" alt="u"/>
                        <span>{{ paymentMethod.name }}</span>
                    </div>
                    <button @click.prevent="addPaymentMethod">Ajouter un mode de paiement</button>
                </div>
            </div>

            <div class="field">
                <div class="fieldTitle">
                    <span>Catégories</span>
                </div>
                <div class="fieldBody">
                    <!-- On boucle les catégories, en ajoutant la possibilité, en cliquant, de voir le détail de la catégorie -->
                    <div class="frameOptions frameCategories" 
                        :style="{'background-color': category.color}" 
                        v-for="category in categories" :key="category.id" 
                        @click.prevent="showCategory(category.id, category.name, category.color, category.default)"
                    >
                        <span>{{ category.name }}</span>
                    </div>
                    <button @click.prevent="addCategory">Ajouter une catégorie</button>
                </div>
            </div>
        </div>
        <PaymentMethod v-if="showPaymentMethodModal"
            :modeProp = modeProp
            :id = idPaymentMethod
            :nameProp = namePaymentMethod
            :logoProp = logoPaymentMethod
            :defaultPMProp = defaultPMProp
            @close="showPaymentMethodModal = false; refreshPaymentMethods()"
        />
        <Category v-if="showCategoryModal"
            :modeProp = modeProp
            :id = idCategory
            :nameProp = nameCategory
            :colorProp = colorCategory
            :defaultCategoryProp = defaultCategoryProp
            @close="showCategoryModal = false; refreshCategories()"
        />
    </div>
</template>

<style scoped>
button {
    margin-top: 25px;
    width: 100%
}

.field {
    height: initial;
    margin-top: 40px;
}

.fieldBody {
    height: initial;
    padding-top: 25px;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import PaymentMethod from '../components/PaymentMethodComponent.vue'
import Category from '../components/CategoryComponent.vue'

export default {
    data () {
        return {
            paymentMethods: null,
            categories: null,
            showPaymentMethodModal: false,
            showCategoryModal: false,
            // Le modeProp servira à définir le mode d'affichage de la prop (read/edit/create/delete)
            modeProp: '',
            // Modale moyens de paiement
            namePaymentMethod: '',
            logoPaymentMethod: '',
            idPaymentMethod: 0,
            // Modale catégories
            nameCategory: '',
            colorCategory: '',
            idCategory: 0,
            defaultPMProp: false,
            defaultCategoryProp: false
        }
    },
    computed: {
        ...mapGetters({
            // On importe les données utilisateur
            user: "getUser",
        }),
    },
    components: {
        PaymentMethod,
        Category
    },
    methods: {
        ...mapActions({
            getPaymentMethods: "paymentMethods",
            getCategories: "categories"
        }),
        showPaymentMethod(id, name, logo, defaultPM) {
            // On set les infos à transmettre a la modale
            this.modeProp = 'read'
            this.idPaymentMethod = id
            this.namePaymentMethod = name
            this.logoPaymentMethod = logo
            this.defaultPMProp = defaultPM
            this.showPaymentMethodModal = true
        },
        addPaymentMethod() {
            this.modeProp = 'create'
            this.namePaymentMethod = ''
            this.logoPaymentMethod = ''
            this.showPaymentMethodModal = true
        },
        // Au close de la modal, on update les données
        refreshPaymentMethods() {
            this.getPaymentMethods()
                .then(response => {
                    this.paymentMethods = response.data
                })
                .catch(error => {
                    this.$toast.warning(error)
                })
        },
        showCategory(id, name, color, defaultCategory) {
            // On set les infos à transmettre a la modale
            this.modeProp = 'read'
            this.idCategory = id
            this.nameCategory = name
            this.colorCategory = color
            this.defaultCategoryProp = defaultCategory
            this.showCategoryModal = true
        },
        addCategory() {
            this.modeProp = 'create',
            this.nameCategory = ''
            this.colorCategory = ''
            this.showCategoryModal = true
        },
        // Au close de la modal, on update les données
        refreshCategories() {
            this.getCategories()
                .then(response => {
                    this.categories = response.data
                })
                .catch(error => {
                    this.$toast.warning(error)
                })
        },
    },
    mounted() {
        // On importe les données
        this.getPaymentMethods()
            .then(response => {
                this.paymentMethods = response.data
            })
            .catch(error => {
                this.$toast.warning(error)
            })
        this.getCategories()
            .then(response => {
                this.categories = response.data
            })
            .catch(error => {
                this.$toast.warning(error)
            })
    },
};
</script>