<template>
    <div class="page">
        <div class="sectionTitle">
            <span>Informations</span>
        </div>

        <!-- Si l'utilisateur vient d'envoyer le formulaire avec succès -->
        <div v-if="registrationOK">
            <p>Votre inscription a été prise en compte !</p>
        </div>
        <!-- Si l'utilisateur est déjà connecté -->
        <div v-else-if="user.isOnline">
            <p>Vous êtes déjà inscrit !</p>
        </div>

        <form @submit.prevent="submitForm" method="post" v-else>
            <div class="field">
                <div class="fieldTitle">
                    <span>Nom d'utilisateur</span>
                </div>
                <div class="fieldBody">
                    <input type="text" placeholder="Philippe" v-model="name" />
                </div>
            </div>

            <div class="field">
                <div class="fieldTitle">
                    <span>Email</span>
                </div>
                <div class="fieldBody">
                    <input type="text" placeholder="exemple@gmail.com" v-model="email" />
                    <span class="errorMessage" v-if="mailFormatError">L'email n'est pas au bon format</span>
                </div>
            </div>

            <div class="field">
                <div class="fieldTitle">
                    <span>Confirmation de l'email</span>
                </div>
                <div class="fieldBody">
                    <input type="text" placeholder="exemple@gmail.com" v-model="emailConfirmation" />
                    <span class="errorMessage" v-if="mailMatchError">La confirmation de l'email ne correspond pas</span>
                </div>
            </div>

            <div class="field">
                <div class="fieldTitle">
                    <span>Mot de passe</span>
                </div>
                <div class="fieldBody">
                    <input type="password" placeholder="************" v-model="password" />
                    <span class="errorMessage" v-if="passwordFormatError">Le mot de passe doit contenir au minimum 1 majuscule, 1 chiffre et 8 caractères minimum</span>
                </div>
            </div>

            <div class="field">
                <div class="fieldTitle">
                    <span>Confirmation du mot de passe</span>
                </div>
                <div class="fieldBody">
                    <input type="password" placeholder="************" v-model="passwordConfirmation" />
                    <span class="errorMessage" v-if="passwordMatchError">La confirmation du mot de passe ne correspond pas</span>
                </div>
            </div>

            <button class="button">
                <input type="submit" value="Confirmer mon inscription">
            </button>
        </form>
    </div>
</template>

<style scoped>
.field {
    margin-top: 40px;
}

.button {
    margin-top: 36px;
    width: 90%;
    background-color: #009F10;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    data () {
        return {
            name: '',
            email: '',
            emailConfirmation: '',
            password: '',
            passwordConfirmation: '',
            mailMatchError: false,
            mailFormatError: false,
            passwordMatchError: false,
            passwordFormatError: false,
            registrationOK: false
        }
    },
    computed: {
        ...mapGetters({
            // On importe les données utilisateur
            user: "getUser",
        }),
    },
    methods: {
        ...mapActions({
            // On importe l'action de login, définie  dans le store
            register: "userRegister",
        }),
        submitForm() {
            // On traite les données entrées 
            if (this.name && this.email && this.emailConfirmation && this.password && this.passwordConfirmation) {
                // Check des formats, serviront comme condition à la soumission du formulaire a l'api
                if (this.email !== this.emailConfirmation) {
                    this.mailMatchError = true
                } else {
                    this.mailMatchError = false
                }
                if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.email)) {
                    this.mailFormatError = true
                } else {
                    this.mailFormatError = false
                }
                if (this.password !== this.passwordConfirmation) {
                    this.passwordMatchError = true
                } else {
                    this.passwordMatchError = false
                }
                // Le pass doit contenir mini 8 caractères, 1 maj mini, 1 chiffre mini
                if(!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(this.password)) {
                    this.passwordFormatError = true
                } else {
                    this.passwordFormatError = false
                }

                if (!this.mailMatchError && !this.mailFormatError && !this.passwordMatchError && !this.passwordFormatError) {
                    // On fait appel à l'action qui se  chargera d'inscrire l'utilisateur par un call api
                    this.register({
                        name: this.name,
                        email: this.email,
                        password: this.password,
                        password_confirmation: this.passwordConfirmation,
                        // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                    }).then(data => {
                        if(data.data.registered) {
                            let toast = (
                                <div>
                                    <h3>Inscription validée !</h3>
                                    <span>Vous allez être redirigé</span>
                                </div>
                            )
                            this.$toast.success(toast)
                            this.registrationOK = true
                            setTimeout(()=>{
                                this.$router.push({ name: 'Account' });
                            }, 5000);
                        }
                        // Et ici l'erreur, envoyée par reject
                    }).catch(error => {
                        this.$toast.warning(error)
                    })
                }
            }
        }
    },
    mounted() {
    },
};
</script>