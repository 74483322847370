import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import store from './store/store'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueApexCharts from 'vue-apexcharts';

// https://github.com/Maronato/vue-toastification#toast-thistoast
const toastsOptions = {
  type: 'info',
  position: 'bottom-left',
  pauseOnFocusLoss: false,
  draggable: false
}

Vue.use(Vuex)
Vue.use(Toast, toastsOptions)
Vue.config.productionTip = false
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
