<template>
    <div class="page">
        <div class="sectionTitle">
            <span>Informations</span>
        </div>
        <!-- Si l'utilisateur est déjà connecté -->
        <div v-if="user.isOnline">
            <p>Vous êtes déjà connecté !</p>
        </div>
        <div v-else>
            <form @submit.prevent="submitForm" method="post">
                <div class="field">
                    <div class="fieldTitle">
                        <span>Email</span>
                    </div>
                    <div class="fieldBody">
                        <input type="text" placeholder="exemple@gmail.com" v-model="email" />
                    </div>
                </div>

                <div class="field">
                    <div class="fieldTitle">
                        <span>Mot de passe</span>
                    </div>
                    <div class="fieldBody">
                        <input type="password" placeholder="************" v-model="password" />
                    </div>
                </div>

                <button class="button">
                    <input type="submit" value="Connexion">
                </button>
            </form>
            <center>
                <div class="semiColons">
                    <button class="button">
                        <span>Inscription</span>
                    </button>
                    <button class="button smallButton">
                        <span><router-link :to="{ name: 'ForgotPassword' }" style="text-decoration: none; color: #F0F0F0;">Mot de passe oublié</router-link></span>
                    </button>
                </div>
            </center>
        </div>
    </div>
</template>

<style scoped>
.field {
    margin-top: 40px;
}

.button {
    margin-top: 36px;
    width: 90%;
}

.semiColons {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.semiColons > .button {
  width: 45%;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    data () {
        return {
            email: '',
            password: '',
        }
    },
    computed: {
        ...mapGetters({
            // On importe l'utilisateur, pour vérifier s'il est déjà connecté
            user: "getUser",
        }),
    },
    methods: {
        ...mapActions({
            // On importe l'action de login, définie  dans le store
            logIn: "userLog",
        }),
        submitForm() {
            // On fait appel à l'action qui se  chargera de logger l'utilisateur par un call api
            this.logIn({
                email: this.email,
                password: this.password,
                }).then(() => {
                    this.$router.push({ name: 'Accounts' })
                }).catch(error => {
                    this.$toast.warning(error)
                })
        }
    },
};
</script>