<template>
    <div class="page">
        <div class="sectionTitle">
            <span>Informations</span>
        </div>
        <!-- Si l'utilisateur est déjà connecté -->
        <div v-if="user.isOnline">
            <p>Vous êtes déjà connecté !</p>
        </div>
        <div v-else>
            <div v-if="isTokenSubmitted">
                <p>Entrez votre email et définissez votre nouveau mot de passe.</p>
                <form @submit.prevent="submitFormReinit" method="post">
                    <div class="field">
                        <div class="fieldTitle">
                            <span>Email</span>
                        </div>
                        <div class="fieldBody">
                            <input type="text" placeholder="exemple@gmail.com" v-model="email" />
                        </div>
                    </div>
                    <div class="field">
                        <div class="fieldTitle">
                            <span>Mot de passe</span>
                        </div>
                        <div class="fieldBody">
                            <input type="password" placeholder="************" v-model="newPassword" />
                            <span class="errorMessage" v-if="passwordFormatError">Le mot de passe doit contenir au minimum 1 majuscule, 1 chiffre et 8 caractères minimum</span>
                        </div>
                    </div>
                    <div class="field">
                        <div class="fieldTitle">
                            <span>Confirmation du mot de passe</span>
                        </div>
                        <div class="fieldBody">
                            <input type="password" placeholder="************" v-model="newPasswordConfirmation" />
                            <span class="errorMessage" v-if="passwordMatchError">La confirmation du mot de passe ne correspond pas</span>
                        </div>
                    </div>

                    <button class="button">
                        <input type="submit" value="Réinitialiser mon mot de passe">
                    </button>
                </form>
            </div>
            <div v-else>
                <p>Entrez votre email pour recevoir un lien vous permettant de réinitialiser votre mot de passe.</p>
                <form @submit.prevent="submitForm" method="post">
                    <div class="field">
                        <div class="fieldTitle">
                            <span>Email</span>
                        </div>
                        <div class="fieldBody">
                            <input type="text" placeholder="exemple@gmail.com" v-model="email" />
                        </div>
                    </div>
                    <div>
                        <span class="errorMessage" v-if="emailError">Vous devez entrer un email.<br /></span>
                        <span class="errorMessage" v-if="mailFormatError && !emailError">L'email n'est pas au bon format.</span>
                    </div>

                    <button class="button">
                        <input type="submit" value="Réinitialiser mon mot de passe">
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<style scoped>
.field {
    margin-top: 40px;
}

.button {
    margin-top: 36px;
    width: 90%;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    data () {
        return {
            email: '',
            emailError: false,
            mailFormatError: false,
            token: this.$route.params.token,
            newPassword: null,
            newPasswordConfirmation: null,
            passwordFormatError: false,
            passwordMatchError: false
        }
    },
    computed: {
        ...mapGetters({
            // On importe l'utilisateur, pour vérifier s'il est déjà connecté
            user: "getUser",
        }),
        isTokenSubmitted: function() {
            if (typeof this.token !== 'undefined') {
                return true
            }
            return false
        }
    },
    methods: {
        ...mapActions({
            // On importe l'action de login, définie  dans le store
            sendMailResetPassword: "sendMailResetPassword",
            resetPassword: "resetPassword"
        }),
        submitForm() {

            this.emailError = false
            this.mailFormatError = false

            this.email === null || this.email === '' ? this.emailError = true : this.emailError = false

            if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.email)) {
                this.mailFormatError = true
            } else {
                this.mailFormatError = false
            }

            if(!this.emailError && !this.mailFormatError) {
                console.log('submit')
                // On fait appel à l'action qui se  chargera de logger l'utilisateur par un call api
                this.sendMailResetPassword({
                    email: this.email,
                    }).then(() => {
                        let toast = (
                            <div>
                                <h3>Demande enregistrée</h3>
                                <span>Vous allez recevoir un mail pour réinitialiser votre mot de passe</span>
                            </div>
                        )
                        this.$toast.success(toast)
                    }).catch(error => {
                        this.$toast.warning(error)
                    })
            }
        },
        submitFormReinit() {

            this.passwordFormatError = false
            this.passwordMatchError = false

            if (this.newPassword !== this.newPasswordConfirmation) {
                this.passwordMatchError = true
            } else {
                this.passwordMatchError = false
            }
            // Le pass doit contenir mini 8 caractères, 1 maj mini, 1 chiffre mini
            if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(this.newPassword)) {
                this.passwordFormatError = true
            } else {
                this.passwordFormatError = false
            }

            if(!this.emailError && !this.mailFormatError) {
                console.log('submit')
                // On fait appel à l'action qui se  chargera de logger l'utilisateur par un call api
                this.resetPassword({
                    email: this.email,
                    token: this.token,
                    password: this.newPassword,
                    password_confirmation: this.newPasswordConfirmation
                    }).then(() => {
                        let toast = (
                            <div>
                                <h3>Mot de passe réinitialisé</h3>
                                <span>Vous allez être redirigé</span>
                            </div>
                        )
                        this.$toast.success(toast)
                        this.$router.push({ name: 'Connection' })
                    }).catch(error => {
                        this.$toast.warning(error)
                    })
            }
        }
    },
};
</script>