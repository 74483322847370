<template>
    <div class="modalContainer">
        <div class="modalContent">
            
            <!-- READ -->
            <div v-if="mode === 'read'">
                <div class="tagsOperations">
                    <div class="modalOperationTags">
                        <div class="frameOptions framePaymentMethods" v-if="operationPaymentMethod">
                            <img :src="operationPaymentMethodLogo" alt="u"/>
                            <span>{{ operationPaymentMethodName }}</span>
                        </div>
                        <div class="frameOptions frameCategories"
                            :style="{'background-color': operationCategoryColor}"
                            v-if="operationCategory"
                        >
                            <span>{{ operationCategoryName }}</span>
                        </div>
                        <div class="frameOptions frameCategories frameRecurrence" v-if="operationRecurrence">
                            <span>{{ operationRecurrenceName }}</span>
                        </div>
                    </div>
                    <div class="modalCloseCross" @click="$emit('close')">
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="currentColor"
                        class="bi bi-x"
                        viewBox="0 0 16 16"
                        >
                        <path
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                        />
                        </svg>
                    </div>
                </div>
                <div class="contentShow">
                    <div class="nameOperation">
                        <span>{{ operationName }}</span>
                        <!-- Ici, on gère la couleur selon l'opérateur du montant -->
                        <span :class="{
                            'positiveAmount': operationAmount >= 0,
                            'negativeAmount': operationAmount < 0
                        }">{{ operationAmount }} €</span>
                    </div>
                    <hr class="separatorOperation"/>
                    <div class="dateOperation">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-event" viewBox="0 0 16 16">
                            <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                        </svg>
                        Le {{ formatDateOperation(operationDate, false) }}
                    </div>
                    <div class="debtOperation" v-if="operationDebt">
                        <span @click="activateDebtComponent('read', operationDebt)">{{ operationDebtAmount }} € dûs {{ typeOperation }} {{ operationDebtUserName }} <span class="badge badge-success" v-if="operationDebtRefunded">Remboursée</span></span>
                    </div>

                    <div class="debtOperation" v-if="operationHided">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                        </svg>
                        <span style="margin-left: 5px;">Opération cachée</span>
                    </div>

                    <div class="field" v-if="operationComment">
                        <div class="fieldTitle">
                            <span>Commentaire</span>
                        </div>
                        <div class="fieldBody">
                            {{ operationComment }}
                        </div>
                    </div>
                </div>

                <!-- On affiche les boutons de modif/suppression que si ce n'est pas l'opé initial du compte ou la première opé du mois, elles sont necessaires aux calculs -->
                <div class="modalButtons">
                    <button class="modalCancelButton" @click="$emit('close')">Annuler</button>
                    <button @click.prevent="mode = 'edit'" v-if="!operationInitialOpe && !operationInitialOpe">Modifier</button>
                    
                    <button class="deleteButton" @click="mode = 'delete'" v-if="!operationInitialOpe && !operationInitialOpe">Supprimer</button>
                </div>
            </div>

            <!-- EDIT - CREATE -->
            <form @submit.prevent="submitForm" method="post" v-else-if="mode === 'edit' || mode === 'create'">
                <div class="tagsOperations">
                    <div class="modalOperationTags">
                        <div class="frameOptions framePaymentMethods frameOptionsReadCreate"
                            v-if="operationPaymentMethod"
                            @click="activatePaymentMethodList()"
                        >
                            <img :src="operationPaymentMethodLogo" alt="u"/>
                            <span>{{ operationPaymentMethodName }}</span>
                            <ul  :class="{
                                    'listPaymentMethods': true,
                                    'categories-display': paymentMethodsDisplay,
                                    'categories-hide': paymentMethodsHide
                                }"
                                v-if="showPaymentMethodList"
                                >
                                <li v-for="paymentMethod in allPaymentMethods" :key="paymentMethod.id"
                                    @click="updatePaymentMethod(paymentMethod)"
                                    class="frameOptions framePaymentMethods frameOptionsReadCreate"
                                >
                                    <img :src="paymentMethod.logo" alt="u"/>
                                    <span>{{ paymentMethod.name }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="frameOptions framePaymentMethods frameOptionsReadCreate" 
                            v-else
                            @click="activatePaymentMethodList()"
                        >
                            <span>Paiement</span>
                            <ul  :class="{
                                    'listPaymentMethods': true,
                                    'categories-display': paymentMethodsDisplay,
                                    'categories-hide': paymentMethodsHide
                                }"
                                v-if="showPaymentMethodList"
                            >
                                <li v-for="paymentMethod in allPaymentMethods" :key="paymentMethod.id"
                                    @click="updatePaymentMethod(paymentMethod)"
                                    class="frameOptions framePaymentMethods frameOptionsReadCreate"
                                >
                                    <img :src="paymentMethod.logo" alt="u"/>
                                    <span>{{ paymentMethod.name }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="frameOptions frameCategories frameOptionsReadCreate"
                            :style="{'background-color': operationCategoryColor}"
                            v-if="operationCategory"
                            @click="activateCategoriesList()"
                        >
                            <span>{{ operationCategoryName }}</span>
                            <ul  :class="{
                                    'listCategories': true,
                                    'categories-display': categoriesDisplay,
                                    'categories-hide': categoriesHide
                                }"
                                v-if="showCategoryList"
                            >
                                <li v-for="category in allCategories" :key="category.id"
                                    :style="{'background-color': category.color}"
                                    @click="updateCategory(category)"
                                >
                                    {{ category.name }}
                                </li>
                            </ul>
                        </div>
                        <div class="frameOptions frameCategories frameOptionsReadCreate"
                            style="background-color: #7CA8FD; color: white;"
                            v-else
                            @click="activateCategoriesList()"
                        >
                            <span>Catégorie</span>
                            <ul  :class="{
                                    'listCategories': true,
                                    'categories-display': categoriesDisplay,
                                    'categories-hide': categoriesHide
                                }"
                                v-if="showCategoryList"
                            >
                                <li v-for="category in allCategories" :key="category.id"
                                    :style="{'background-color': category.color}"
                                    @click="updateCategory(category)"
                                >
                                    {{ category.name }}
                                </li>
                            </ul>
                        </div>
                        <div class="frameOptions frameCategories frameRecurrence" v-if="operationRecurrence">
                            <span>{{ operationRecurrenceName }}</span>
                        </div>
                    </div>
                    <div class="modalCloseCross" @click="$emit('close')">
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="currentColor"
                        class="bi bi-x"
                        viewBox="0 0 16 16"
                        >
                        <path
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                        />
                        </svg>
                    </div>
                </div>
                <div class="contentShow">
                    <div class="nameOperation">
                        <input type="text" placeholder="Opération" v-model="operationName"/>
                        <!-- Ici, on gère la couleur selon l'opérateur du montant -->
                        <input type="number" 
                            placeholder="0.00" 
                            :class="{
                                'inputAmountOperation': true,
                                'positiveAmount': operationAmount >= 0,
                                'negativeAmount': operationAmount < 0
                            }"
                            v-model="operationAmount"
                            step="0.01"
                        />
                        <span    
                        :class="{
                            'inputAmountOperation': true,
                            'positiveAmount': operationAmount >= 0,
                            'negativeAmount': operationAmount < 0
                        }">€</span>
                    </div>
                    <span class="errorMessage" v-if="nameError">Vous devez définir un nom d'opération.</span>
                    <span class="errorMessage" v-if="amountError">Vous devez définir un montant.</span>
                    <hr class="separatorOperation"/>
                    <div class="accountOperation">
                        Compte :
                        <select v-model="selectedAccountForOperation" @change="changeMinDate($event)">
                            <option v-for="account in operationAccounts" 
                            :value="account.id" :key="account.id"
                            >
                                {{ account.name }}
                            </option>
                        </select>
                    </div>
                    <span class="errorMessage" v-if="accountError">Vous devez définir un compte.</span>
                    <div class="dateOperation">
                        <input type="date" v-model="operationDate" v-if="operationDate" :disabled="deactivateDateSelection" :min="minDate"/>
                    </div>
                    <span class="errorMessage" v-if="dateError">Vous devez définir une date.</span>
                    <div class="debtOperation">
                        <span v-if="operationDebt" @click="activateDebtComponent('read', operationDebt)">{{ operationDebtAmount }} € dûs {{ typeOperation }} {{ operationDebtUserName }} <span v-if="operationDebtRefunded">(remboursé)</span></span>
                        <span v-else @click="activateDebtComponent('create')" class="modalInternalButton">Ajouter un dû</span>
                    </div>
                    <div class="recurrenceOperation" v-if="!operationRecurrence" @click="activateRecurrentComponent('create')">
                        <span class="modalInternalButton">Ajouter une récurrence</span>
                    </div>

                    <div class="recurrenceOperation" style="flex-wrap: wrap">
                        <span v-if="operationHided == 1" class="modalInternalButton" style="cursor:pointer" @click="toggleHideOperation()">Exposer l'opération</span>
                        <span class="modalInternalButton" style="cursor:pointer" @click="toggleHideOperation()" v-else>Cacher l'opération</span><br/>
                        <span style="font-size: 14px; margin-left: 5px;">Actuellement: 
                            <span v-if="operationHided == 1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                                    <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                                </svg>
                                <span style="margin-left: 5px">Cachée</span>
                            </span>
                            <span v-else>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                </svg>
                                <span style="margin-left: 5px">Exposée</span>
                            </span>
                        </span>
                    </div>

                    <div class="field">
                        <div class="fieldTitle">
                            <span>Commentaire</span>
                        </div>
                        <div class="fieldBody">
                            <textarea placehodler="commentaire" v-model="operationComment"/>
                        </div>
                    </div>
                </div>

                <div class="modalButtons">
                    <button  class="validationButton"> 
                        <input type="submit" value="Valider"/>
                    </button>
                    <button class="modalCancelButton" @click="$emit('close')">Annuler</button>
                </div>

                <Recurrence
                    v-if="showRecurrenceComponent"
                    :modePropRecurrence = modePropRecurrence
                    :minDateProp = minDate
                    @close="showRecurrenceComponent = false"
                    @recurrenceAdded="updateRecurrence"
                />
            </form>
            
            <!-- DELETE -->
            <div v-if="mode === 'delete'">
                <div class="modalCloseCross" @click="$emit('close')">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="currentColor"
                    class="bi bi-x"
                    viewBox="0 0 16 16"
                    >
                    <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                    />
                    </svg>
                </div>
                <div class="contentShow">
                    <p>Souhaitez-vous vraiment supprimer cette opération ?</p>

                    <div class="modalButtons">
                        <button class="modalCancelButton" @click="$emit('close')">Annuler</button>
                        <button class="deleteButton" @click="submitForm">Supprimer</button>
                    </div>
                </div>
            </div>

            <Debt
                v-if="showDebtComponent"
                :modePropDebt = modePropDebt
                :debtProp = debtProp
                @close="showDebtComponent = false"
                @debtChanged="updateDebt"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Recurrence from "./RecurrenceComponent.vue"
import Debt from "./DebtComponent.vue"

export default {
    props: [
        'modeProp',
        'id',
        'nameProp',
        'colorProp',
        'operationProp',
        'formatDateOperation',
        'accountsProp',
        'selectedAccountIdProp',
        'minDateProp'
    ],
    components: {
        Recurrence,
        Debt
    },
    data () {
        return {
            // On défini des variables qui prenent la valeur des props, puisqu'elles seront sujettes à modifications
            mode: this.modeProp,
            name: this.nameProp,
            color: this.colorProp,
            operationAmount: null,
            operationName: null,
            operationDate: new Date(),
            operationDebt: null,
            operationDebtId: null,
            operationDebtType: null,
            operationDebtAmount: null,
            operationDebtUserName: null,
            operationDebtRefunded: null,
            operationComment: null,
            operationPaymentMethod: null,
            operationPaymentMethodId: null,
            operationPaymentMethodLogo: null,
            operationPaymentMethodName: null,
            operationCategory: null,
            operationCategoryId: null,
            operationCategoryColor: null,
            operationCategoryName: null,
            operationRecurrence: null,
            operationRecurrenceId: null,
            operationRecurrenceName: null,
            operationAccounts: null,
            operationInitialOpe: null,
            operationFirstOpeOfMonth: null,
            operationHided: 0,
            selectedAccountForOperation: null,
            categories: null,
            paymentMethods: null,
            showCategoryList: false,
            showPaymentMethodList: false,
            showRecurrenceComponent: false,
            modePropRecurrence: null,
            recurrenceStartDate: null,
            deactivateDateSelection: false,
            showDebtComponent: false,
            modePropDebt: null,
            debtProp: null,
            minDate: this.minDateProp,
            nameError: false,
            amountError: false,
            dateError: false,
            accountError: false,
            categoriesDisplay: null,
            categoriesHide: null,
            paymentMethodsDisplay: null,
            paymentMethodsHide: null
        }
    },
    computed: {
        ...mapGetters({
            // On importe les données utilisateur
            user: "getUser",
        }),
        typeOperation() {
            // On affiche le type de l'opé en francais
            return this.operationDebtType === 'by' ? 'par' : 'à'
        },
        // Ici, on filtre les méthoides de paiement, pour ne pas afficher la méthode de paiement selectionnée dans la liste de choix
        allPaymentMethods: function() {
            if (this.operationPaymentMethodId) {
                return this.paymentMethods.filter(p => p.id !== this.operationPaymentMethodId)
            } else {
                return this.paymentMethods
            }
        },
        // Ici, on filtre les catégories, pour ne pas afficher la catégorie selectionnée dans la liste de choix
        allCategories: function() {
            if (this.categories) {
                return this.categories.filter(c => c.id !== this.operationCategoryId)
            } else {
                return this.categories
            }
        }
    },
    methods: {
        ...mapActions({
            updateOperation: "updateOperation",
            storeOperation: "createOperation",
            deleteOperation: "deleteOperation",
            getCategories: "categories",
            getPaymentMethods: "paymentMethods",
            showRecurrence: "showRecurrence",
            showDebt: "showDebt",
            minDateByOperation: "minDateByOperation"
        }),
        activateCategoriesList() {
            // On trigger les animations
            this.triggerCategoriesAnimation()
            // Pour que la liste ne soit pas cachée immédiatement a la fermeture de la liste, on met un timeout seulement si on demande la fermeture
            if (this.categoriesHide) {
                setTimeout(()=>{
                    this.showCategoryList = !this.showCategoryList
                }, 300);
            } else {
                this.showCategoryList = !this.showCategoryList
            }
        },
        activatePaymentMethodList() {
            // On trigger les animations
            this.triggerPaymentMethodsAnimation()
            // Pour que la liste ne soit pas cachée immédiatement a la fermeture de la liste, on met un timeout seulement si on demande la fermeture
            if (this.paymentMethodsHide) {
                setTimeout(()=>{
                    this.showPaymentMethodList = !this.showPaymentMethodList
                }, 300);
            } else {
                this.showPaymentMethodList = !this.showPaymentMethodList
            }
        },
        triggerPaymentMethodsAnimation() {
            if (this.paymentMethodsDisplay === null) {
                this.paymentMethodsDisplay = true;
                this.paymentMethodsHide = false;
            } else {
                this.paymentMethodsDisplay = !this.paymentMethodsDisplay
                this.paymentMethodsHide = !this.paymentMethodsHide
            }
        },
        triggerCategoriesAnimation() {
            if (this.categoriesDisplay === null) {
                this.categoriesDisplay = true;
                this.categoriesHide = false;
            } else {
                this.categoriesDisplay = !this.categoriesDisplay
                this.categoriesHide = !this.categoriesHide
            }
        },
        toggleHideOperation() {
            this.operationHided === 1 ? this.operationHided = 0 : this.operationHided = 1;
        },
        submitForm() {
            if(this.mode === 'delete') {
                // On fait appel l'action delete
                this.deleteOperation({
                    id: this.operationProp.id
                    // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                }).then(() => {
                    let toast = (
                        <div>
                            <span>L'opération a bien été supprimée.</span>
                        </div>
                    )
                    this.$toast.success(toast)
                    this.$emit('close')
                    // Et ici l'erreur, envoyée par reject
                }).catch(error => {
                    this.$toast.warning(error)
                })
            }

            /************ VALIDATION DES DONNEES **********/
            this.operationName === null || this.operationName === '' ? this.nameError = true : this.nameError = false
            this.operationAmount === null || this.operationAmount === '' ? this.amountError = true : this.amountError = false
            this.operationDate === null || this.operationDate === '' ? this.dateError = true : this.dateError = false
            this.selectedAccountForOperation === null || this.selectedAccountForOperation === '' ? this.accountError = true : this.accountError = false
            
            // On traite les données entrées 
            if (!this.nameError && !this.amountError && !this.dateError && !this.accountError) {
                if(this.mode === 'edit') {
                    // On fait appel l'action update
                    this.updateOperation({
                        id: this.operationProp.id,
                        account_id: this.selectedAccountForOperation,
                        amount: this.operationAmount,
                        name: this.operationName,
                        date: this.operationDate,
                        category_id: this.operationCategoryId,
                        payment_method_id: this.operationPaymentMethodId,
                        debt_id: this.operationDebtId,
                        recurrence_id: this.operationRecurrenceId,
                        comment: this.operationComment,
                        hided: this.operationHided
                        // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                    }).then(() => {
                        let toast = (
                            <div>
                                <span>L'opération a bien été mise à jour.</span>
                            </div>
                        )
                        this.$toast.success(toast)
                        this.$emit('close')
                        // Et ici l'erreur, envoyée par reject
                    }).catch(error => {
                        this.$toast.warning(error)
                    })
                } else if (this.mode === 'create') {
                    // On fait appel l'action store
                    this.storeOperation({
                        account_id: this.selectedAccountForOperation,
                        amount: this.operationAmount,
                        name: this.operationName,
                        date: this.operationDate,
                        category_id: this.operationCategoryId,
                        payment_method_id: this.operationPaymentMethodId,
                        debt_id: this.operationDebtId,
                        recurrence_id: this.operationRecurrenceId,
                        comment: this.operationComment,
                        hided: this.operationHided
                        // On peut récupérer la réponse du promise (envoyée par resolve par le store)
                    }).then(() => {
                        let toast = (
                            <div>
                                <span>L'opération a bien été créée.</span>
                            </div>
                        )
                        this.$toast.success(toast)
                        this.$emit('close')
                        // Et ici l'erreur, envoyée par reject
                    }).catch(error => {
                        this.$toast.warning(error)
                    })
                }
            }
        },
        initVariablesFromProps() {
            this.operationAccounts = this.accountsProp
            // On formatte la date du jour pour fit au input date
            this.operationDate = this.operationDate.getFullYear() + '-' + this.operationDate.toLocaleString('default', { month: '2-digit'}) + '-' + this.operationDate.getDate()
            // Si on detecte une operation
            if(this.operationProp) {
                //On récupère la date min avec l'id de l'opé
                this.getMinDateByOperation(this.operationProp.id)

                this.operationAmount = this.operationProp.amount
                this.operationName = this.operationProp.name
                this.operationDate = this.operationProp.date
                this.operationComment = this.operationProp.comment
                this.operationInitialOpe = this.operationProp.initial_ope 
                this.operationFirstOpeOfMonth = this.operationProp.first_ope_of_month
                this.operationHided = this.operationProp.hided
                if (this.selectedAccountIdProp) {
                    this.selectedAccountForOperation = this.selectedAccountIdProp
                } else {
                    this.selectedAccountForOperation = this.operationProp.account_id
                }
                if (this.operationProp.debt) {
                    this.operationDebt = this.operationProp.debt
                    this.operationDebtId = this.operationProp.debt.id
                    this.operationDebtAmount = this.operationProp.debt.amount
                    this.operationDebtUserName = this.operationProp.debt.debt_user.name
                    this.operationDebtRefunded = this.operationProp.debt.refunded
                    this.operationDebtType = this.operationProp.debt.type
                }
                if (this.operationProp.payment_method) {
                    this.updatePaymentMethod(this.operationProp.payment_method)
                }
                if (this.operationProp.category) {
                    this.updateCategory(this.operationProp.category)
                }
                if (this.operationProp.recurrence) {
                    this.operationRecurrence = this.operationProp.recurrence
                    this.operationRecurrenceId = this.operationProp.recurrence.id
                    this.operationRecurrenceName = this.operationProp.recurrence.recurrence_name.name
                }
            }
        },
        getListTags() {
            // On choppe la liste des catégories
            this.getCategories()
                .then((response) => {
                    this.categories = response.data
                    // Et ici l'erreur, envoyée par reject
                }).catch(error => {
                    this.$toast.warning(error)
                })
                
            // On choppe la liste des moyens de paiement
            this.getPaymentMethods()
                .then((response) => {
                    this.paymentMethods = response.data
                    // Et ici l'erreur, envoyée par reject
                }).catch(error => {
                    this.$toast.warning(error)
                })
        },
        updateCategory(category) {
            this.operationCategory = category
            this.operationCategoryId = category.id
            this.operationCategoryColor = category.color
            this.operationCategoryName = category.name
        },
        updatePaymentMethod(paymentMethod) {
            this.operationPaymentMethod = paymentMethod
            this.operationPaymentMethodId = paymentMethod.id
            this.operationPaymentMethodLogo = paymentMethod.logo
            this.operationPaymentMethodName = paymentMethod.name
        },
        updateRecurrence(recurrenceID, startDate) {
            // Si c'est un retour de la modale de récurrence, on la ferme
            if(this.showRecurrenceComponent) {
                this.showRecurrenceComponent = false
            }
            this.operationRecurrenceId = recurrenceID

            // On a ajouté une récurrence, la date de la première opé est donc la date de la première récurrence
            this.operationDate = startDate
            // On empêche donc la selection de la date
            this.deactivateDateSelection = true

            // On a une nouvelle récurrence, on va donc mettre à jour la vue
            this.showRecurrence({
                    id: this.operationRecurrenceId
                })
                .then((response) => {
                    this.operationRecurrence = response.data
                    this.operationRecurrenceName = response.data.recurrence_name.name
                    // Et ici l'erreur, envoyée par reject
                }).catch(error => {
                    this.$toast.warning(error)
                })
        },
        updateDebt(deleted, debtID) {
            // Si c'est un retour de la modale de récurrence, on la ferme
            if(this.showDebtComponent) {
                this.showDebtComponent = false
            }
            if(deleted) {
                this.operationDebt = null,
                this.operationDebtId = null,
                this.operationDebtType = null,
                this.operationDebtAmount = null,
                this.operationDebtUserName = null,
                this.operationDebtRefunded = null
            } else {
                this.operationDebtId = debtID

                // On a une nouvelle dette, on va donc mettre à jour la vue
                this.showDebt({
                        id: this.operationDebtId
                    })
                    .then((response) => {
                        this.operationDebt = response.data,
                        this.operationDebtId = response.data.id,
                        this.operationDebtType = response.data.type,
                        this.operationDebtAmount = response.data.amount,
                        this.operationDebtUserName = response.data.debt_user.name,
                        this.operationDebtRefunded = response.data.refunded
                        // Et ici l'erreur, envoyée par reject
                    }).catch(error => {
                        this.$toast.warning(error)
                    })
            }
        },
        activateRecurrentComponent(mode) {
            this.modePropRecurrence = mode
            this.showRecurrenceComponent = true
        },
        activateDebtComponent(mode, debt = null) {
            this.modePropDebt = mode
            this.showDebtComponent = true
            this.debtProp = debt
        },
        changeMinDate(event) {
            for (let account of this.operationAccounts) {
                // Si c'est le compte qu'on vient de selectionner, on change la date min
                if (account.id === parseInt(event.target.value)) {
                    this.minDate = account.min_date
                }
            }
        },
        getMinDateByOperation(operationId) {
            this.minDateByOperation({
                    id: operationId
                })
                .then((response) => {
                    this.minDate = response.data.min_date
                    // Et ici l'erreur, envoyée par reject
                }).catch(error => {
                    this.$toast.warning(error)
                })
        }
    },
    mounted() {
        this.initVariablesFromProps()
        this.getListTags()
    }
}
</script>

<style scoped>
.field {
    height: initial;
    margin-top: 0;
}

.fieldTitle {
    margin-left: 0;
}

.frameOptions {
    margin: 5px;
    width: 100px;
    height: 20px;
    padding: 0 4px;
    font-size: 12px;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 25%);
    border-radius: 5px;
    background-color: rgb(167, 196, 255)
}

.framePaymentMethods img{
    width: 12px;
    height: 12px;
}

.frameRecurrence {
    background-color: #0018EC
}

.listCategories {
    width: 100px;
    border-radius: 2px;
    position: absolute;
    /* 13px de margin-top pour chaque li + les 18px de base de l'élément affiché */
    top: 16px;
}

.listCategories > li {
    margin-top: 13px !important;
    width: 100px;
    border-radius: 5px;
    background-color: #0018EC
}

.listPaymentMethods {
    position: absolute;
    /* 13px de margin-top pour chaque li + les 15px de base de l'élément affiché */
    top: 16px;
    left: 0;
}

.listPaymentMethods > li {
    margin-top: 13px !important;
    width: 100px;
}

.tagsOperations,
.nameOperation {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nameOperation {
    font-size: 24px;
}

.modalOperationTags {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 80%;
    flex-wrap: wrap;
}

.dateOperation,
.debtOperation,
.recurrenceOperation,
.accountOperation {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px;
}

.accountOperation > select{
 margin-left: 5px;
}

.dateOperation {
    /* On ajuste la hauteur a l'oeil, la vraie hauteur avec le align-itmes parait trop basse */
    margin-top: 3px;
}

.dateOperation svg {
    margin-right: 7px;
}

.separatorOperation {
    border-color: #B2CDFF;
    margin-top: 10px;
    margin-bottom: 20px;
}

.inputAmountOperation {
    text-align: right;
}
</style>
