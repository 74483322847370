var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[(_vm.user.isOnline)?_c('div',[_c('div',{staticClass:"sectionTitle"},[(_vm.debtUser)?_c('span',[_vm._v(_vm._s(_vm.debtUser.name)+" ")]):_c('span',[_vm._v("Tous les utilisateurs ")])]),_c('div',[_c('div',{staticClass:"accounts",on:{"click":function($event){return _vm.activateDebtsList()}}},[_c('div',{staticClass:"accountName"},[_c('span',[_vm._v("Liste des utilisateurs ")]),_c('span',[(!_vm.showUsers)?_c('svg',{staticClass:"bi bi-arrow-down-square-fill icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5a.5.5 0 0 1 1 0z"}})]):_vm._e(),(_vm.showUsers)?_c('svg',{staticClass:"bi bi-arrow-up-square-fill icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M2 16a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2zm6.5-4.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 1 0z"}})]):_vm._e()])]),(_vm.showUsers)?_c('div',{class:{
                                'categories-display': _vm.debtsDisplay,
                                'categories-hide': _vm.debtsHide
                            }},[_c('p',{staticClass:"accountName",on:{"click":function($event){return _vm.resetUser()}}},[_vm._v("Tous les utilisateurs")]),_vm._l((_vm.debtUsers),function(debtUser){return _c('p',{key:debtUser.id,staticClass:"accountName",on:{"click":function($event){return _vm.selectUser(debtUser.id)}}},[_vm._v(_vm._s(debtUser.name))])})],2):_vm._e()])]),_c('div',[_c('center',[_c('div',{staticClass:"semiColons",style:({'justify-content': _vm.debtUser ? null : 'center'})},[_c('button',{staticClass:"button smallButton",on:{"click":function($event){return _vm.activateDebtUserComponent('create')}}},[_c('span',[_vm._v("Ajouter un utilisateur")])]),(_vm.debtUser)?_c('button',{staticClass:"button deleteButton smallButton",on:{"click":function($event){return _vm.activateDebtUserComponent('delete', _vm.debtUser.id, _vm.debtUser.name)}}},[_c('span',[_vm._v("Supprimer cet utilisateur")])]):_vm._e()])])],1),_c('div',{staticClass:"field"},[_c('div',{staticClass:"fieldBody"},[_c('div',{staticClass:"balance"},[_c('span',[_vm._v("Prêt en cours")]),_c('span',{staticClass:"positiveAmount"},[_vm._v(_vm._s(_vm.byAmount)+" €")])]),_c('div',{staticClass:"balance"},[_c('span',[_vm._v("Emprunt en cours")]),_c('span',{staticClass:"negativeAmount"},[_vm._v(_vm._s(_vm.toAmount)+" €")])])])]),_c('div',[(_vm.filterByDebtUser(_vm.unrefundedDebts).length > 0)?_c('div',{staticClass:"field"},[_c('div',{staticClass:"fieldTitle"},[_vm._v(" En cours ")]),_vm._l((_vm.filterByDebtUser(_vm.unrefundedDebts)),function(debt,index){return _c('div',{key:debt.id,class:{
                    'fieldBody': true,
                    'operation': true,
                    'groupDate': (index > 0)},on:{"click":function($event){return _vm.activateOperationComponent(debt.operation)}}},[_c('div',[_c('span',{staticClass:"nameOperation"},[_vm._v(_vm._s(debt.operation.name))]),_c('span',{class:{
                            'positiveAmount': debt.type === 'by',
                            'negativeAmount': debt.type === 'to'
                        }},[_c('span',[_vm._v(_vm._s(debt.amount)+" €")])])]),_c('div',[_c('span',{staticClass:"typeOperation"},[_vm._v(_vm._s(_vm.typeOperation(debt.type)))]),_c('span',{staticClass:"typeOperation"},[_vm._v(_vm._s(_vm.formatDateOperation(debt.operation.date)))])])])})],2):_vm._e(),(_vm.filterByDebtUser(_vm.refundedDebts).length > 0)?_c('div',{staticClass:"field"},[_c('div',{staticClass:"fieldTitle"},[_vm._v(" Remboursé ")]),_vm._l((_vm.filterByDebtUser(_vm.refundedDebts)),function(debt,index){return _c('div',{key:debt.id,class:{
                    'fieldBody': true,
                    'operation': true,
                    'groupDate': (index > 0)},on:{"click":function($event){return _vm.activateOperationComponent(debt.operation)}}},[_c('div',[_c('span',{staticClass:"nameOperation"},[_vm._v(_vm._s(debt.operation.name))]),_c('span',{class:{
                            'positiveAmount': debt.type === 'by',
                            'negativeAmount': debt.type === 'to'
                        }},[_c('span',[_vm._v(_vm._s(debt.amount)+" €")])])]),_c('div',[_c('span',{staticClass:"typeOperation"},[_vm._v(_vm._s(_vm.typeOperation(debt.type)))]),_c('span',{staticClass:"typeOperation"},[_vm._v(_vm._s(_vm.formatDateOperation(debt.operation.date)))])])])})],2):_vm._e()]),(_vm.showDebtUserModal)?_c('DebtUser',{attrs:{"modeProp":_vm.modeProp,"debtUserIDProp":_vm.debtUserIDProp,"debtUserNameProp":_vm.debtUserNameProp},on:{"close":function($event){_vm.showDebtUserModal = false; _vm.refreshDebtUsers(); _vm.getAllDebts(); _vm.debtUser = null}}}):_vm._e(),(_vm.showOperationComponent)?_c('Operation',{attrs:{"modeProp":"read","operationProp":_vm.operationProp,"formatDateOperation":_vm.formatDateOperation,"accountsProp":_vm.accountsProp},on:{"close":function($event){_vm.showOperationComponent = false; _vm.refreshDebtUsers(); _vm.getAllDebts(); _vm.debtUser = null}}}):_vm._e()],1):_c('p',[_vm._v("Vous devez être connecté pour accéder à cette partie !")])])}
var staticRenderFns = []

export { render, staticRenderFns }