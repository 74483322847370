<template>
    <div class="page">
        <div class="sectionTitle">
            <span>Informations</span>
        </div>

        <!-- Si l'utilisateur est déjà connecté -->
        <div v-if="!user.isOnline">
            <p>Vous devez être connecté pour accéder à cette section !</p>
        </div>

        <!--
        -->

        <div v-else>
            <!-- READ -->
            <div v-if="mode === 'read'">
                <div class="field">
                    <div class="fieldTitle">
                        <span>Nom d'utilisateur</span>
                    </div>
                    <div class="fieldBody">
                        {{ name }}
                    </div>
                </div>

                <div class="field">
                    <div class="fieldTitle">
                        <span>Email</span>
                    </div>
                    <div class="fieldBody">
                        {{ email }}
                    </div>
                </div>

                <div class="sectionTitle second">
                    <span>Configuration</span>
                </div>
                <button :class="{
                    'button': true,
                    'deleteButton': showStats,
                    'validationButton': !showStats
                    }" 
                    @click="toggleShowStats()"
                >
                    <span v-if="showStats">Je ne souhaite plus utiliser les statistiques</span>
                    <span v-else>Je souhaite utiliser les statistiques</span>
                </button>                
                <div class="field">
                    <div class="fieldTitle">
                        <span>Mot de passe</span>
                    </div>
                    <div class="fieldBody">
                        <input type="password" placeholder="**********" v-model="actualPassword" />
                    </div>
                </div>
                <button class="button" @click="submitConfig()">Enregistrer</button>

                <div class="sectionTitle second">
                    <span>Opérations</span>
                </div>

                <button class="button" @click="mode = 'edit'">Modifier mes informations</button>
                <button class="button deleteButton" @click="mode = 'delete'">Supprimer mon compte</button>
            </div>

            <!-- EDIT -->
            <form @submit.prevent="submitForm" method="post" v-else-if="mode === 'edit'">
                <div class="field">
                    <div class="fieldTitle">
                        <span>Nom d'utilisateur</span>
                    </div>
                    <div class="fieldBody">
                        <input type="text" placeholder="Philippe" v-model="name" />
                    </div>
                    <span class="errorMessage" v-if="nameError">Le nom est obligatoire</span>
                </div>

                <div class="field">
                    <div class="fieldTitle">
                        <span>Email</span>
                    </div>
                    <div class="fieldBody">
                        <input type="text" placeholder="exemple@gmail.com" v-model="email" />
                    </div>
                    <span class="errorMessage" v-if="mailMatchError">L'email et sa confirmation ne concordent pas.</span>
                    <span class="errorMessage" v-if="mailFormatError">L'émail n'est pas au bon format.</span>
                </div>

                <div class="field">
                    <div class="fieldTitle">
                        <span>Confirmation email</span>
                    </div>
                    <div class="fieldBody">
                        <input type="text" placeholder="exemple@gmail.com" v-model="emailConfirmation" />
                    </div>
                </div>

                <div class="sectionTitle second">
                    <span>Mot de passe</span>
                </div>

                <div class="field">
                    <div class="fieldTitle">
                        <span>Nouveau mot de passe</span>
                    </div>
                    <div class="fieldBody">
                        <input type="password" placeholder="**********" v-model="newPassword" />
                    </div>
                    <span class="errorMessage" v-if="passwordFormatError">Le mot de passe doit contenir minimum 8 caractères, 1 maj mini, 1 chiffre</span>
                    <span class="errorMessage" v-if="passwordMatchError">Le mot de passe et sa confirmation ne concordent pas.</span>
                </div>

                <div class="field">
                    <div class="fieldTitle">
                        <span>Confirmation nouveau mot de passe</span>
                    </div>
                    <div class="fieldBody">
                        <input type="password" placeholder="**********" v-model="newPasswordConfirmation" />
                    </div>
                </div>

                <div class="field">
                    <div class="fieldTitle">
                        <span>Mot de passe actuel</span>
                    </div>
                    <div class="fieldBody">
                        <input type="password" placeholder="**********" v-model="actualPassword" />
                    </div>
                    <span class="errorMessage" v-if="actualPasswordError">Vous devez entrer votre mot de passe actuel pour valider les modifications.</span>
                </div>

                <div class="modalButtons">
                    <button  class="validationButton"> 
                        <input type="submit" value="Valider"/>
                    </button>
                    <button class="modalCancelButton" @click="mode = 'read'">Annuler</button>
                </div>
            </form>
           
            <!-- DELETE -->
            <div class="contentShow" v-if="mode === 'delete'">
                <p>Souhaitez-vous vraiment supprimer votre compte ?</p>
                <p><strong>CETTE ACTION EST IRREVERSIBLE</strong></p>
                <p>Confirmez en recopiant la phrase "{{ confirmationTemplate }}"</p>

                <div class="field">
                    <div class="fieldTitle">
                        <span>Confirmation</span>
                    </div>
                    <div class="fieldBody">
                        <input type="text" :placeholder="confirmationTemplate" v-model="confirmation"/>
                    </div>
                    <span class="errorMessage" v-if="confirmationError">Veuillez confirmer la suppression en entrant les mots "{{ confirmationTemplate }}"</span>
                </div>

                <div class="modalButtons">
                    <button class="modalCancelButton" @click="mode = 'read'">Annuler</button>
                    <button class="deleteButton" @click="submitForm">Supprimer</button>
                </div>
            </div>

        </div>
    </div>
</template>

<style scoped>
.field {
    margin-top: 40px;
}

.button {
    margin-top: 36px;
    width: 90%;
}

.second {
    margin-top: 45px;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    data () {
        return {
            name: null,
            email: null,
            emailConfirmation: null,
            mailMatchError: false,
            mailFormatError: false,
            newPassword: null,
            newPasswordConfirmation: null,
            actualPassword: null,
            passwordFormatError: false,
            passwordMatchError: false,
            mode: 'read',
            confirmationTemplate: 'Je souhaite supprimer mon compte',
            confirmation: null,
            confirmationError: false,
            nameError: false,
            emailError: false,
            actualPasswordError: false,
            showStats: 0
        }
    },
    computed: {
        ...mapGetters({
            // On importe les données utilisateur
            user: "getUser",
        }),
    },
    methods: {
        ...mapActions({
            // On importe l'action de login, définie  dans le store
            register: "userRegister",
            updateUser: "updateUser",
            deleteUser: "deleteUser",
        }),
        toggleShowStats() {
            this.showStats = !this.showStats
        },
        submitConfig() {
            let datasToSend = {
                id: this.user.id,
                name: this.name,
                email: this.email,
                actualPassword: this.actualPassword,
                show_stats: this.showStats
            }
            this.updateUser(datasToSend).then(() =>{
                let toast = (
                    <div>
                        <h3>Validé !</h3>
                        <span>Vous informations ont bien été mises a jour !</span>
                    </div>
                )
                this.$toast.success(toast)
            }).catch(error => {
                this.$toast.warning(error)
            })
        },
        submitForm() {
            // On souhaite supprimer le compte
            if (this.mode === 'delete') {
                if (this.confirmation === this.confirmationTemplate) {
                    // Le minimum vital a envoyer au back, qu'on va remplir au fur et à mesure des infos entrées
                    let datasToSend = {
                        id: this.user.id,
                    }

                    this.deleteUser(datasToSend).then().catch(error => {
                        this.$toast.warning(error)
                    })
                } else {
                    this.confirmationError = true
                }
            }
            
            /***************** VERIFICATION DES DONNEES *****************/

            //On s'assure d'abord qu'on ait bien un name, un email, et un passwordactuel
            this.name === null || this.name === '' ? this.nameError = true : this.nameError = false
            this.email === null || this.email === '' ? this.emailError = true : this.emailError = false
            this.actualPassword === null || this.actualPassword === '' === null ? this.actualPasswordError = true : this.actualPasswordError = false

            //Si on a un nouveau mdp c'est qu'on souhaite surement en changer
            if (this.newPassword) {
                // Le pass doit contenir mini 8 caractères, 1 maj mini, 1 chiffre mini
                if(!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(this.newPassword)) {
                    this.passwordFormatError = true
                } else {
                    this.passwordFormatError = false
                }
                
                if (this.newPassword !== this.newPasswordConfirmation) {
                    this.passwordMatchError = true
                } else {
                    this.passwordMatchError = false
                }
            }

            //Si on a un nouveau mail c'est qu'on souhaite surement en changer
            if (this.emailConfirmation) {
                
                if (this.email !== this.emailConfirmation) {
                    this.mailMatchError = true
                } else {
                    this.mailMatchError = false
                }
                if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.emailConfirmation)) {
                    this.mailFormatError = true
                } else {
                    this.mailFormatError = false
                }
            }


            if (!this.nameError && !this.emailError && !this.actualPasswordError && !this.mailFormatError && !this.mailMatchError && !this.passwordFormatError && !this.passwordMatchError) {
                if(this.mode === 'edit') {
                    // Le minimum vital a envoyer au back, qu'on va remplir au fur et à mesure des infos entrées
                    let datasToSend = {
                        id: this.user.id,
                        name: this.name,
                        email: this.email,
                        actualPassword: this.actualPassword,
                    }

                    // Si on a donc un pass et aucune erreur qui lui est lié
                    if (this.newPassword && !this.passwordFormatError && !this.passwordMatchError) {
                        // Si on a pas d'erreur liée au pass
                        if(!this.passwordMatchError && !this.passwordFormatError) {
                            datasToSend.new_password = this.newPassword
                        }
                    }


                    // Si on a donc un mail et aucune erreur qui lui est lié
                    if (this.emailConfirmation && !this.mailMatchError && !this.mailFormatError) {

                        // Si on a pas d'erreur liée aux mails
                        if (!this.mailMatchError && !this.mailFormatError) {
                            datasToSend.new_email = this.email
                        }
                    }

                    this.updateUser(datasToSend).then(() =>{
                        let toast = (
                            <div>
                                <h3>Validé !</h3>
                                <span>Vous informations ont bien été mises a jour !</span>
                            </div>
                        )
                        this.$toast.success(toast)
                        this.mode = 'read'
                    }).catch(error => {
                        this.$toast.warning(error)
                    })
                }
            }
        }
    },
    mounted() {
        this.name = this.user.name
        this.email = this.user.email
        this.showStats = this.user.showStats
    },
};
</script>